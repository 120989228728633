html,
body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    html,
    body {
      background: unset; } }

body {
  padding-top: 114px;
  padding-top: 150px;
  background-color: #fff; }
  @media only screen and (max-width: 1200px) {
    body {
      padding-top: 136px;
      padding-top: 164px; } }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    body {
      padding-top: 165px;
      padding-top: 149px; } }

/*#root {
    width: 100%;
    width: 100vw;
    overflow-x: hidden;
    >div {
        >div {
            width: 100%;
            float: left;
            overflow-x: hidden;
        }
    }
}*/
.text-bold {
  font-weight: 600; }

button {
  cursor: pointer; }

.content-wrap {
  padding: 0; }

.breadcrumb {
  background-color: transparent;
  padding: 0;
  padding-bottom: 38px;
  margin: 0; }
  .breadcrumb > li {
    font-size: 18px; }
    .breadcrumb > li::after {
      content: "//";
      padding: 0 6px; }
    .breadcrumb > li a {
      color: #333333; }
    .breadcrumb > li:last-child::after {
      content: ''; }
    .breadcrumb > li:last-child a {
      color: #F7617E;
      font-weight: 600; }
  @media only screen and (max-width: 768px) {
    .breadcrumb {
      display: none; } }

.section {
  padding: 20px 0;
  float: left;
  width: 100%; }
  @media only screen and (max-width: 768px) {
    .section {
      padding: 10px 0; } }
  .section h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 22px;
    text-align: center; }
    .section h2 > span {
      font-weight: 400; }
    @media only screen and (max-width: 768px) {
      .section h2 {
        font-size: 20px; } }
  .section .header-top {
    display: none; }
  .section .home-wrap .header {
    display: none; }
  .section .home-wrap .contact-phone {
    display: none; }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: rgba(35, 38, 40, 0.9);
  z-index: 9999; }
  .lightbox .mdi-close {
    position: fixed;
    top: 16px;
    right: 16px;
    font-size: 48px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 99999; }
  .lightbox .lightbox-item {
    padding: 100px 200px;
    width: 100%;
    height: 100vh; }
    .lightbox .lightbox-item img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center; }
    .lightbox .lightbox-item .title {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding-top: 16px; }
  .lightbox .carousel-control-prev-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }
  .lightbox .carousel-control-next-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }

.section-newsletter {
  background-color: #333333;
  padding: 60px 0;
  margin: 60px 0; }
  .section-newsletter .title {
    text-align: center; }
    .section-newsletter .title h2 {
      color: #FFFFFF; }
      .section-newsletter .title h2::after {
        content: " ";
        display: block;
        width: 100px;
        height: 4px;
        background-color: #FFFFFF;
        margin: 22px auto 36px auto; }
    .section-newsletter .title p {
      color: #FFFFFF;
      font-size: 18px;
      margin-bottom: 38px; }
  @media only screen and (max-width: 768px) {
    .section-newsletter > div {
      text-align: center; } }
  .section-newsletter input {
    border-radius: 6px;
    height: 50px;
    border: 1px solid #CCCCCC;
    outline: none;
    padding: 0 20px;
    width: 70%;
    font-size: 18px; }
    @media only screen and (max-width: 768px) {
      .section-newsletter input {
        width: 100%; } }
  .section-newsletter .button {
    height: 50px;
    margin-left: 20px;
    border-radius: 6px;
    background-color: #FFFFFF;
    color: #333333; }
    @media only screen and (max-width: 768px) {
      .section-newsletter .button {
        margin-top: 20px;
        margin-left: 0; } }

.section-articles a {
  text-decoration: none; }

.error-text {
  margin: 16px 0;
  color: #DB3838; }

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: rem-calc(5); }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F7617E;
  border-radius: rem-calc(5);
  cursor: pointer; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F7617E;
  cursor: pointer; }

.styled-numbers {
  margin-bottom: 20px;
  list-style: none; }
  .styled-numbers span {
    font-weight: 600;
    color: #F7617E;
    margin-right: 8px; }

.text-primary {
  color: #F7617E !important; }

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05); }
  .cookies P {
    color: #333333;
    margin-bottom: 0; }
  .cookies button {
    margin: 0 16px;
    border: 0;
    background-color: #333333;
    color: #FFFFFF;
    padding: 6px 16px; }
    .cookies button:hover {
      background-color: #F7617E; }
  .cookies a {
    color: #333333;
    font-weight: 600;
    font-size: 18px; }
  @media only screen and (max-width: 768px) {
    .cookies {
      flex-direction: column;
      height: unset;
      padding: 32px 16px; }
      .cookies button {
        margin-top: 16px; } }

.pop-up-messages {
  position: fixed;
  right: 32px;
  top: 275px;
  z-index: 999999;
  width: 400px; }
  @media only screen and (max-width: 768px) {
    .pop-up-messages {
      width: 90%;
      margin-left: 5%;
      top: 0;
      margin-top: 50px;
      left: 0;
      right: unset; } }
  .pop-up-messages > div {
    padding: 42px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer; }
    .pop-up-messages > div .hide {
      color: #FFFFFF;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 22px;
      cursor: pointer; }
    .pop-up-messages > div > p {
      color: #FFFFFF;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .pop-up-messages > div > p::before {
        content: ' ';
        display: block;
        width: 10px;
        height: 20px;
        border-bottom: 3px solid #97ef9d;
        border-right: 3px solid #97ef9d;
        transform: rotate(45deg);
        float: left;
        margin-right: 16px; }
    .pop-up-messages > div .error::before {
      display: none; }
  .pop-up-messages .hide-message {
    animation-name: hide-message;
    animation-duration: 1s;
    transform: translate3d(110%, 0, 0); }

@keyframes hide-message {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(110%, 0, 0); } }
  .pop-up-messages .mdi-close {
    color: #DB3838;
    font-size: 24px;
    margin-right: 8px; }

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 520px;
  margin-left: -400px;
  margin-top: -260px;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 21;
  animation-name: rotate;
  animation-duration: 1s; }

@keyframes rotate {
  from {
    top: 0; }
  to {
    top: 50%; } }
  @media only screen and (max-width: 768px) {
    .popup {
      width: 90%;
      margin-left: 5%;
      left: 0;
      height: 400px; } }
  .popup .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .popup .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 64px; }
    @media only screen and (max-width: 768px) {
      .popup .content {
        padding: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    .popup .content .coupon {
      width: 425px;
      transform: rotate(-6deg);
      margin-bottom: 40px; }
      @media only screen and (max-width: 768px) {
        .popup .content .coupon {
          width: 70%; } }
      @media only screen and (max-width: 768px) {
        .popup .content .coupon {
          margin-bottom: 10px; } }
    .popup .content h2 {
      font-size: 46px;
      color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .popup .content h2 {
          font-size: 24px; } }
    .popup .content h3 {
      font-size: 24px;
      color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .popup .content h3 {
          font-size: 18px; } }
    .popup .content h6 {
      font-size: 15px;
      color: #CCCCCC;
      font-style: italic;
      margin-bottom: 20px; }
      @media only screen and (max-width: 768px) {
        .popup .content h6 {
          font-size: 12px; } }
    .popup .content .button {
      position: absolute;
      bottom: 120px;
      left: 50%;
      margin-left: -120px;
      background-color: #000; }

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 19;
  background-color: rgba(0, 0, 0, 0.3); }

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px; }

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #F7617E;
  border-color: #F7617E transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.not-found-content {
  text-align: center;
  padding: 38px 0; }
  .not-found-content h1 {
    font-size: 100px !important; }
  .not-found-content h2 {
    font-size: 32px !important;
    margin-bottom: 32px; }

article {
  position: relative; }
  article .discount {
    position: absolute;
    right: 0;
    top: 20px;
    background-color: #B3DE5B;
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 6px 0 0 6px;
    font-weight: 600;
    font-size: 18px; }
    @media only screen and (max-width: 768px) {
      article .discount {
        right: 0px; } }

.list-article .discount {
  right: 0; }

.discount-detail {
  position: absolute;
  right: 15px;
  top: 20px;
  background-color: #B3DE5B;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 6px 0 0 6px;
  font-weight: 600;
  font-size: 18px;
  z-index: 19; }

.news-detail h1 {
  margin-bottom: 24px; }

.news-detail img {
  width: 100%;
  margin-bottom: 16px; }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: cssAnimation  0.7s forwards;
  animation: cssAnimation  0.7s forwards;
  pointer-events: none; }

@keyframes cssAnimation {
  0% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.find-client-wrap {
  margin-bottom: 32px;
  position: relative; }
  .find-client-wrap ul {
    position: absolute;
    top: 92px;
    background-color: #FFFFFF;
    width: 100%;
    padding: 32px 16px;
    list-style: none;
    z-index: 9999;
    border: 1px solid #D1D1D1; }
    .find-client-wrap ul > li {
      padding: 8px 16px; }
      .find-client-wrap ul > li:hover {
        background-color: #F5F5F5;
        cursor: pointer; }

.delete-client {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .delete-client .isvg {
    cursor: pointer; }

.deliveryBox-container {
  border: 1px solid #F7617E;
  margin-bottom: 32px; }
  .deliveryBox-container p {
    margin-bottom: 0; }
  .deliveryBox-container .text {
    padding: 15px; }

.deliveryBox {
  background: #F7617E;
  color: white;
  padding: 8px 16px; }
  .deliveryBox h6 {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0; }

.section-bottom {
  background-color: #FFF2FE;
  padding-top: 80px; }
  .section-bottom .title {
    margin-bottom: 52px; }
  .section-bottom h6 {
    text-align: center;
    color: #F7617E; }
  .section-bottom a {
    text-decoration: none; }
  .section-bottom .instagram-photos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px; }
    @media only screen and (max-width: 768px) {
      .section-bottom .instagram-photos {
        flex-wrap: wrap; } }
    .section-bottom .instagram-photos a {
      width: 100%;
      margin: 0 10px; }
      @media only screen and (max-width: 768px) {
        .section-bottom .instagram-photos a {
          margin: 0 5px;
          width: 45%; }
          .section-bottom .instagram-photos a:first-child {
            width: 100%; } }
      .section-bottom .instagram-photos a img {
        width: 100%;
        height: 360px;
        object-fit: cover; }
        @media only screen and (max-width: 768px) {
          .section-bottom .instagram-photos a img {
            height: unset;
            margin-bottom: 20px; } }
  .section-bottom .item {
    text-align: center; }
    .section-bottom .item svg {
      margin-bottom: 20px;
      height: 40px; }
    .section-bottom .item p {
      font-size: 22px;
      font-weight: 600;
      color: #333333; }
      @media only screen and (max-width: 768px) {
        .section-bottom .item p {
          font-size: 18px; } }

.section-categories .col-12 {
  padding-left: 0;
  padding-right: 0; }

.section-categories .category-item {
  width: 100%;
  position: relative;
  margin-bottom: 15px; }
  @media only screen and (max-width: 992px) {
    .section-categories .category-item {
      margin-bottom: 5px; } }
  .section-categories .category-item img {
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .section-categories .category-item img {
        height: 150px;
        object-fit: cover; } }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      .section-categories .category-item img {
        height: 200px;
        object-fit: cover; } }
  .section-categories .category-item .name {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .section-categories .category-item h6 {
    font-size: 48px;
    font-weight: 700;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 0 80px;
    text-align: center;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
    line-height: 50px; }
    @media only screen and (max-width: 768px) {
      .section-categories .category-item h6 {
        font-size: 32px;
        line-height: 28px; } }

.order-done-title {
  text-align: center; }

.order-done h3 {
  text-align: center;
  font-size: 42px !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important; }
  @media only screen and (max-width: 768px) {
    .order-done h3 {
      font-size: 30px !important;
      margin-bottom: 8px !important; } }

.order-done h6 {
  text-align: center !important;
  font-weight: 400 !important;
  font-size: 26px !important; }
  @media only screen and (max-width: 768px) {
    .order-done h6 {
      font-size: 22px !important; } }

.stripe-loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9999; }
  .stripe-loading .order-loader {
    text-align: center;
    padding: 20px 0; }
  .stripe-loading .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px; }
  .stripe-loading .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #F7617E;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #F7617E transparent transparent transparent; }
  .stripe-loading .lds-ring div:nth-child(1) {
    animation-delay: -0.45s; }
  .stripe-loading .lds-ring div:nth-child(2) {
    animation-delay: -0.3s; }
  .stripe-loading .lds-ring div:nth-child(3) {
    animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.order-loading h3 {
  text-align: center;
  font-size: 42px !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important; }
  @media only screen and (max-width: 768px) {
    .order-loading h3 {
      font-size: 30px !important;
      margin-bottom: 8px !important; } }

.order-loading h6 {
  text-align: center !important;
  font-weight: 400 !important;
  font-size: 26px !important; }
  @media only screen and (max-width: 768px) {
    .order-loading h6 {
      font-size: 22px !important; } }

.order-loading .order-loader {
  text-align: center;
  padding: 20px 0; }

.order-loading .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.order-loading .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #F7617E;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #F7617E transparent transparent transparent; }

.order-loading .lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.order-loading .lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.order-loading .lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.card-number-wrap {
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid #CCCCCC;
  padding: 10px 10px 20px 10px; }

.pay-button {
  background-color: #333333;
  width: 100%;
  border: 0;
  padding: 10px;
  color: #FFFFFF;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: all 0.3s;
  outline: none; }
  .pay-button:hover {
    background-color: #F7617E; }

.address-info {
  margin-bottom: 20px; }
  .address-info h3 {
    margin-bottom: 10px !important;
    font-size: 18px; }
  .address-info p {
    margin-bottom: 3px;
    font-size: 14px;
    color: #333333;
    font-weight: 400; }
  @media only screen and (max-width: 767px) {
    .address-info {
      margin-bottom: 20px; } }

.vat {
  font-size: 14px;
  font-weight: 400;
  color: #717171; }

.payment-selector {
  margin-bottom: 20px;
  margin-top: 20px; }
  .payment-selector button {
    border: 0;
    outline: none;
    background-color: #FFF2FE;
    padding: 15px 30px; }
    .payment-selector button.active {
      background-color: #F7617E;
      color: #FFFFFF; }

.payment-account h6 {
  margin-bottom: 5px !important; }

.payment-account button {
  border: 0;
  outline: none;
  background-color: #333333;
  padding: 12px 40px;
  transition: all 0.3s;
  color: #FFFFFF; }
  .payment-account button:hover {
    background-color: #F7617E;
    color: #FFFFFF; }

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05); }
  @media only screen and (max-width: 767px) {
    .cookies {
      z-index: 9999999999; } }
  .cookies P {
    color: #333333;
    font-size: 12px;
    margin-bottom: 0; }
  .cookies button {
    margin: 0 16px;
    border: 0;
    background-color: #333333;
    color: #FFFFFF;
    padding: 6px 16px; }
    .cookies button:hover {
      background-color: #F7617E; }
  .cookies a {
    color: #333333;
    font-weight: 600;
    font-size: 18px; }
  @media only screen and (max-width: 768px) {
    .cookies {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      height: unset;
      padding: 20px 16px; }
      .cookies p {
        margin-bottom: 16px; } }

.add-to-cart-btn {
  display: flex;
  align-items: center;
  font-size: 14px !important; }
  @media only screen and (max-width: 768px) {
    .add-to-cart-btn {
      width: 100% !important;
      justify-content: center; } }
  .add-to-cart-btn svg {
    fill: none; }
    @media only screen and (max-width: 768px) {
      .add-to-cart-btn svg {
        margin-right: 10px; } }
    .add-to-cart-btn svg path, .add-to-cart-btn svg circle {
      stroke: #FFFFFF; }

.cards-accepted {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; }
  .cards-accepted img {
    max-width: 300px;
    width: 100%; }

.payment-options {
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 10px 0;
  margin-bottom: 30px; }
  .payment-options > div > button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 32px;
    padding-left: 42px;
    background-color: transparent;
    border: 0;
    outline: none;
    border-bottom: 1px solid #eee;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .payment-options > div > button {
        padding-right: 10px; } }
    .payment-options > div > button:before {
      content: ' ';
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #eee;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 10px; }
    .payment-options > div > button img {
      height: 26px; }
      @media only screen and (max-width: 767px) {
        .payment-options > div > button img {
          height: 14px; } }
  .payment-options > div > div {
    height: 0;
    overflow: hidden;
    transition: all 0.3s; }
  .payment-options > div .credit-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .payment-options > div .credit-card svg {
      width: 100px;
      margin-bottom: 10px; }
    .payment-options > div .credit-card p {
      text-align: center; }
  .payment-options > div.active > button:after {
    content: ' ';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #F7617E;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    left: 15px; }
  .payment-options > div.active > div {
    height: auto;
    padding: 50px 20px;
    background-color: #f5f5f5; }
  .payment-options > div:last-child > button {
    border-bottom: 0; }

.options-field {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .options-field {
      flex-direction: column; } }
  .options-field button {
    border: 1px solid #eee;
    padding: 5px;
    background-color: transparent;
    position: relative;
    padding: 10px;
    padding-left: 42px;
    font-size: 14px;
    outline: none; }
    @media only screen and (max-width: 767px) {
      .options-field button {
        margin-bottom: 10px; } }
    .options-field button:before {
      content: ' ';
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #eee;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 10px; }
    .options-field button.active {
      color: #F7617E; }
      .options-field button.active:after {
        content: ' ';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #F7617E;
        position: absolute;
        top: 50%;
        margin-top: -7px;
        left: 15px; }

.free-products-modal .modal-title {
  text-align: center;
  font-size: 22px;
  width: 100%;
  text-transform: uppercase;
  color: #F7617E;
  font-weight: 700; }
  .free-products-modal .modal-title span {
    color: #333333;
    font-weight: 600; }

.free-product {
  margin-bottom: 20px; }
  .free-product img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    margin-bottom: 10px; }
  .free-product h6 {
    color: #333333;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    min-height: 48px; }
  .free-product button {
    width: 100%;
    background-color: #F7617E; }

.add-to-cart-popup {
  position: fixed;
  right: 20px;
  top: 150px;
  width: 320px;
  z-index: 9999;
  background-color: #FFFFFF;
  padding: 20px 10px;
  height: auto;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 767px) {
    .add-to-cart-popup {
      left: 20px;
      width: unset; } }
  .add-to-cart-popup .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px; }
    .add-to-cart-popup .buttons > button {
      width: 100%;
      border: 0;
      outline: none;
      margin-bottom: 5px;
      padding: 5px;
      font-size: 14px;
      background-color: transparent;
      border: 2px solid #333333;
      border-radius: 5px; }
    .add-to-cart-popup .buttons a > button {
      font-size: 14px;
      padding: 5px;
      width: 100%;
      border: 0;
      outline: none;
      margin-bottom: 5px;
      background-color: #F7617E;
      color: #FFFFFF;
      border: 2px solid #F7617E;
      border-radius: 5px; }
  .add-to-cart-popup h3 {
    font-size: 14px;
    margin-bottom: 5px; }
  .add-to-cart-popup p {
    font-size: 12px;
    margin-bottom: 5px; }
  .add-to-cart-popup .similar-articles {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .add-to-cart-popup .similar-articles > div {
      padding: 5px;
      width: 50%; }
      .add-to-cart-popup .similar-articles > div article {
        min-height: unset;
        margin-bottom: 0; }
      .add-to-cart-popup .similar-articles > div img {
        width: 100%;
        height: 100px; }
        @media only screen and (max-width: 767px) {
          .add-to-cart-popup .similar-articles > div img {
            height: unset; } }
      .add-to-cart-popup .similar-articles > div h6 {
        font-size: 10px;
        height: 24px;
        margin: 5px 0 0px 0; }
      .add-to-cart-popup .similar-articles > div p {
        font-size: 12px; }
      .add-to-cart-popup .similar-articles > div button {
        font-size: 11px; }

.order-done .payment-account {
  margin-top: 20px;
  text-align: center; }

.free-shipping-calc {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .free-shipping-calc .price {
    font-weight: 700;
    margin-right: 10px;
    margin-left: 10px;
    color: #F7617E; }
  .free-shipping-calc svg {
    width: 32px;
    margin-right: 10px; }
    .free-shipping-calc svg path, .free-shipping-calc svg line, .free-shipping-calc svg circle {
      stroke: #F7617E;
      stroke-width: 4; }

.forgot-password-wrapper {
  margin-top: 10px; }

.forgot-password {
  font-size: 14px;
  color: #333333; }

.ratings-box {
  text-align: center; }
  .ratings-box h6 {
    text-align: center;
    margin-bottom: 10px; }
  .ratings-box .stars {
    margin-bottom: 50px; }
    .ratings-box .stars button {
      background-color: transparent;
      border: 0;
      outline: none; }
      .ratings-box .stars button svg {
        width: 38px;
        height: 38px; }

.cart-ts {
  font-size: 12px;
  font-weight: 400; }
  @media only screen and (max-width: 767px) {
    .cart-ts {
      font-size: 10px; } }
  .cart-ts > span {
    color: red;
    font-weight: 600; }

.ratings-product {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ratings-product h1 {
    font-size: 28px !important;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .ratings-product h1 {
        font-size: 24px !important; } }
  .ratings-product h3 {
    font-size: 18px !important;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .ratings-product h3 {
        font-size: 14px !important; } }
  .ratings-product .rating-product {
    display: flex;
    margin-bottom: 20px; }
    .ratings-product .rating-product img {
      width: 100px;
      height: auto;
      object-fit: contain;
      object-position: top;
      margin-right: 10px; }
    .ratings-product .rating-product > div p {
      font-size: 14px;
      margin-bottom: 2px; }
    .ratings-product .rating-product > div h6 {
      margin-bottom: 5px; }
  .ratings-product .stars {
    margin-bottom: 0px; }
    .ratings-product .stars button {
      background-color: transparent;
      border: 0;
      outline: none; }
      .ratings-product .stars button svg {
        width: 24px;
        height: 24px; }

.newsletter {
  position: relative;
  width: 100%;
  float: left;
  height: 400px; }
  @media only screen and (max-width: 767px) {
    .newsletter {
      height: 640px; } }
  .newsletter .mobile-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 640px;
    background-color: rgba(0, 0, 0, 0.5); }
    @media only screen and (max-width: 767px) {
      .newsletter .mobile-overlay {
        display: block; } }
  .newsletter .title {
    padding: 50px; }
    @media only screen and (max-width: 767px) {
      .newsletter .title {
        padding: 40px 15px; } }
    .newsletter .title h2 {
      font-size: 36px; }
      @media only screen and (max-width: 767px) {
        .newsletter .title h2 {
          font-size: 24px;
          color: #FFFFFF; } }
    .newsletter .title h6 {
      font-size: 24px;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .newsletter .title h6 {
          font-size: 18px;
          color: #FFFFFF; } }
    .newsletter .title > div {
      display: flex;
      align-items: center; }
    .newsletter .title input {
      padding: 8px;
      width: 50%;
      border: 0; }
      @media only screen and (max-width: 767px) {
        .newsletter .title input {
          width: 60%;
          font-size: 12px;
          padding: 11px; } }
  .newsletter > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 400px;
    object-fit: cover; }
    @media only screen and (max-width: 767px) {
      .newsletter > img:nth-child(1) {
        display: none; } }
    .newsletter > img:nth-child(2) {
      display: none; }
      @media only screen and (max-width: 767px) {
        .newsletter > img:nth-child(2) {
          display: block;
          height: 640px; } }

.bold-text {
  font-weight: 700 !important; }
  .bold-text a {
    font-weight: 700 !important;
    color: #F7617E !important; }

.home-page-categories-wrap {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .home-page-categories-wrap {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center; } }

@media only screen and (max-width: 767px) {
  .home-page-categories-item {
    width: 50%; } }

@media only screen and (max-width: 768px) {
  .checkbox-wrap-mobile {
    display: flex;
    align-items: center; }
    .checkbox-wrap-mobile span {
      margin-left: 7px; }
    .checkbox-wrap-mobile .checkbox {
      min-width: 20px; } }

.gratis-products-message-wrap {
  margin-bottom: 20px;
  color: #f7617e;
  font-size: 14px;
  font-weight: 600; }

.disabled-btn {
  background-color: rgba(247, 97, 126, 0.5) !important; }

.gratis-products-wrap {
  border-bottom: 1px solid #D1D1D1;
  margin-bottom: 20px;
  padding: 0 !important; }
  .gratis-products-wrap .gratis-products-title {
    margin-bottom: 15px; }
  .gratis-products-wrap .gratis-products-item-wrap article {
    min-height: 200px; }
  .gratis-products-wrap .gratis-products-item-wrap img {
    height: 150px;
    object-fit: cover; }
  .gratis-products-wrap .gratis-products-item-wrap h6 {
    margin-bottom: 10px !important;
    font-size: 16px !important; }

.gratis-product-message-percent-wrap {
  display: flex;
  flex-direction: column;
  margin: 15px 0; }
  .gratis-product-message-percent-wrap .gratis-product-message-percent-prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px; }
  .gratis-product-message-percent-wrap h6 {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0; }
  .gratis-product-message-percent-wrap .percent-line {
    width: 100%;
    background-color: #e5e5e5;
    border-radius: 6px;
    height: 10px; }
  .gratis-product-message-percent-wrap .percent-line-pink {
    background-color: #F7617E;
    width: 0%;
    height: 10px;
    border-radius: 6px; }

.gratis-product-cart-wrap {
  margin-top: 20px;
  border-bottom: 1px solid #D1D1D1; }
  .gratis-product-cart-wrap h6 {
    font-size: 18px !important;
    font-weight: 600;
    margin-bottom: 20px !important; }
    .gratis-product-cart-wrap h6 span {
      color: #F7617E; }
  .gratis-product-cart-wrap .free-product {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .gratis-product-cart-wrap .free-product h6 {
      margin-bottom: 10px !important; }
    .gratis-product-cart-wrap .free-product button {
      width: 220px; }
      @media only screen and (max-width: 767px) {
        .gratis-product-cart-wrap .free-product button {
          width: 100%; } }

.gratis-product-cart-message h3 {
  font-size: 14px !important;
  margin-bottom: 5px !important; }

.gratis-product-cart-message p {
  font-size: 12px !important;
  margin-bottom: 5px !important; }

.gratis-product-cart-message h6 {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important; }

.gratis-product-cart-message .buttons {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-bottom: 10px; }
  @media only screen and (max-width: 767px) {
    .gratis-product-cart-message .buttons {
      width: 100%; } }
  .gratis-product-cart-message .buttons > button {
    width: 100%;
    border: 0;
    outline: none;
    margin-bottom: 5px;
    padding: 5px;
    font-size: 14px;
    background-color: transparent;
    border: 2px solid #333333;
    border-radius: 5px; }
  .gratis-product-cart-message .buttons a > button {
    width: 100%;
    border: 0;
    outline: none;
    margin-bottom: 5px;
    padding: 5px;
    font-size: 14px;
    background-color: transparent;
    border: 2px solid #333333;
    border-radius: 5px; }

.gratis-product-cart-message .gratis-product-message-percent-wrap {
  width: 400px; }
  @media only screen and (max-width: 767px) {
    .gratis-product-cart-message .gratis-product-message-percent-wrap {
      width: 100%; } }

.choose-gratis-wrap {
  padding-bottom: 20px;
  margin-left: 10px;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .choose-gratis-wrap {
      width: 100%; } }
  .choose-gratis-wrap .choose-gratis-btn {
    width: 250px;
    text-align: left;
    border: 2px solid #F7617E;
    border-radius: 6px;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #F7617E;
    padding: 8px 20px;
    font-weight: 600; }
    @media only screen and (max-width: 767px) {
      .choose-gratis-wrap .choose-gratis-btn {
        width: 100%; } }
    .choose-gratis-wrap .choose-gratis-btn:focus {
      border: 2px solid #F7617E !important;
      outline: none !important; }
    .choose-gratis-wrap .choose-gratis-btn::after {
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      border-bottom: 2px solid #F7617E;
      border-right: 2px solid #F7617E;
      transform: rotate(45deg);
      margin-top: -5px; }
  .choose-gratis-wrap .choose-gratis-items-wrap {
    border-radius: 6px;
    -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 15px 20px;
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 9;
    top: 50px;
    left: 0;
    background-color: #FFFFFF; }
    .choose-gratis-wrap .choose-gratis-items-wrap .free-product img {
      width: 100%;
      height: 100px;
      object-fit: contain;
      margin-bottom: 10px; }
      @media only screen and (max-width: 767px) {
        .choose-gratis-wrap .choose-gratis-items-wrap .free-product img {
          height: 120px; } }
    .choose-gratis-wrap .choose-gratis-items-wrap .free-product h6 {
      color: #333333;
      font-weight: 600;
      text-align: center;
      margin-bottom: 5px !important;
      font-size: 13px !important;
      min-height: 30px; }
    .choose-gratis-wrap .choose-gratis-items-wrap .free-product button {
      width: 100%;
      max-width: 150px;
      border: 2px solid #F7617E;
      border-radius: 6px;
      background-color: #F7617E;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      padding: 4px 20px;
      font-weight: 600;
      font-size: 14px !important; }

.flex-center-mob-column {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .flex-center-mob-column {
      flex-direction: column; } }

.link-text-remove-style a {
  text-decoration: none !important;
  color: #FFFFFF !important; }

.button {
  color: #FFFFFF;
  background-color: #000;
  padding: 8px 28px;
  text-decoration: none;
  outline: none;
  border: none; }
  .button .isvg {
    margin-right: 8px; }

.button-black {
  background-color: #333333; }

.button-right {
  float: right; }

.big-button {
  height: 50px; }

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  background-color: #FFF2FE;
  /*-webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    */ }
  header .shipping-info {
    background-color: #fef7e4;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center; }
    header .shipping-info svg {
      width: 24px;
      height: 24px; }
    @media only screen and (max-width: 991px) {
      header .shipping-info {
        font-size: 12px;
        flex-direction: column; } }
    header .shipping-info a {
      margin: 0 20px;
      color: #F7617E; }
  header .header {
    padding-top: 14px;
    padding-bottom: 20px; }
    @media only screen and (max-width: 991px) {
      header .header {
        padding-top: 18px;
        padding-bottom: 18px; } }
    header .header > .row {
      align-items: center; }
    header .header .mobile-hamburger {
      display: none; }
      @media only screen and (max-width: 991px) {
        header .header .mobile-hamburger {
          padding: 0;
          display: flex;
          align-items: center; }
          header .header .mobile-hamburger svg {
            fill: #FFFFFF; } }
    header .header .mobile-actions {
      padding-right: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media only screen and (min-width: 1200px) {
        header .header .mobile-actions {
          display: none; } }
      header .header .mobile-actions button {
        background-color: transparent;
        outline: none;
        border: 0;
        margin: 0 3px; }
      header .header .mobile-actions .dropdown {
        margin-left: auto; }
        header .header .mobile-actions .dropdown > button {
          background-color: transparent;
          border: 0 !important;
          outline: none !important;
          box-shadow: none !important;
          color: #333333 !important;
          display: flex;
          align-items: center; }
          header .header .mobile-actions .dropdown > button > span:last-child {
            display: none; }
          header .header .mobile-actions .dropdown > button .isvg {
            display: block !important; }
          header .header .mobile-actions .dropdown > button::after {
            content: ' ';
            width: 8px;
            height: 8px;
            border-bottom: 2px solid #333333;
            border-right: 2px solid #333333;
            display: block;
            transform: rotate(45deg);
            margin-left: 6px;
            margin-top: -5px; }
          header .header .mobile-actions .dropdown > button svg, header .header .mobile-actions .dropdown > button img {
            width: 26px;
            height: 26px;
            stroke: unset;
            fill: unset;
            margin-right: 3px; }
          header .header .mobile-actions .dropdown > button img {
            height: auto; }
        header .header .mobile-actions .dropdown .dropdown-menu a {
          margin: 0 !important;
          display: block; }
        header .header .mobile-actions .dropdown .dropdown-menu svg, header .header .mobile-actions .dropdown .dropdown-menu img {
          width: 26px;
          height: 26px;
          stroke: unset;
          fill: unset; }
        header .header .mobile-actions .dropdown .dropdown-menu img {
          height: auto; }
        header .header .mobile-actions .dropdown .dropdown-menu button {
          font-size: 16px !important; }
          header .header .mobile-actions .dropdown .dropdown-menu button:hover {
            background-color: #FFF2FE; }
      header .header .mobile-actions .mobile-search svg path {
        fill: #333333; }
      header .header .mobile-actions .mobile-search svg line {
        stroke: #333333; }
      header .header .mobile-actions a {
        margin: 0 3px;
        position: relative; }
        header .header .mobile-actions a svg {
          fill: none;
          stroke: #333333; }
        header .header .mobile-actions a .cart-count {
          position: absolute;
          top: -10px;
          right: -10px;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          background-color: #F7617E;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          color: #FFFFFF !important; }
    header .header .hamburger {
      display: none; }
      @media only screen and (max-width: 1200px) {
        header .header .hamburger {
          display: flex !important;
          align-items: center; }
          header .header .hamburger svg path {
            fill: #333333; } }
    header .header .logo {
      display: flex;
      align-items: center;
      justify-content: center; }
      header .header .logo svg {
        width: 100%; }
        @media only screen and (max-width: 991px) {
          header .header .logo svg {
            width: 100%; } }
    header .header .navigation ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      header .header .navigation ul li {
        position: relative; }
        header .header .navigation ul li a {
          color: #333333;
          font-size: 15px;
          font-weight: 500;
          text-decoration: none;
          padding: 12px 0;
          display: block; }
          @media only screen and (max-width: 1200px) {
            header .header .navigation ul li a {
              font-size: 12px; } }
        header .header .navigation ul li:hover a {
          color: #F7617E; }
          header .header .navigation ul li:hover a::after {
            content: ' ';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -4px;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background-color: #F7617E; }
      header .header .navigation ul .active a {
        color: #F7617E; }
        header .header .navigation ul .active a::after {
          content: ' ';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -4px;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background-color: #F7617E; }
    header .header .account {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      justify-content: space-between; }
      @media only screen and (max-width: 1200px) {
        header .header .account {
          color: transparent;
          display: none !important; } }
      header .header .account .dropdown {
        margin-left: auto; }
        header .header .account .dropdown > button {
          background-color: transparent;
          border: 0 !important;
          outline: none !important;
          box-shadow: none !important;
          color: #333333 !important;
          display: flex;
          align-items: center; }
          header .header .account .dropdown > button::after {
            content: ' ';
            width: 8px;
            height: 8px;
            border-bottom: 2px solid #333333;
            border-right: 2px solid #333333;
            display: block;
            transform: rotate(45deg);
            margin-left: 10px;
            margin-top: -5px; }
          header .header .account .dropdown > button svg, header .header .account .dropdown > button img {
            width: 32px;
            height: 32px; }
          header .header .account .dropdown > button img {
            object-fit: contain; }
          header .header .account .dropdown > button img {
            margin-right: 5px; }
        header .header .account .dropdown .dropdown-menu a {
          margin: 0 !important;
          display: block; }
        header .header .account .dropdown .dropdown-menu svg {
          width: 32px;
          height: 32px; }
        header .header .account .dropdown .dropdown-menu img {
          width: 32px;
          object-fit: contain;
          margin-left: 10px;
          margin-right: 5px; }
        header .header .account .dropdown .dropdown-menu button {
          font-size: 16px !important; }
          header .header .account .dropdown .dropdown-menu button:hover {
            background-color: #FFF2FE; }
      header .header .account .social {
        display: flex;
        border-left: 1px solid #333333;
        padding: 4px 0; }
      header .header .account .account-button {
        display: flex;
        align-items: center;
        padding: 5px 20px;
        min-height: 45px;
        border-left: 1px solid #333333;
        max-width: 180px; }
      header .header .account .isvg {
        margin-right: 5px;
        margin-left: 10px; }
        @media only screen and (max-width: 991px) {
          header .header .account .isvg {
            margin-right: 0;
            margin-left: 0; } }
        header .header .account .isvg svg {
          fill: #333333; }
      header .header .account a {
        color: #333333;
        font-size: 18px;
        font-weight: 600;
        margin: 0 5px;
        line-height: 16px;
        text-decoration: none; }
        @media only screen and (max-width: 991px) {
          header .header .account a {
            display: none; } }
      header .header .account .account-text a {
        float: left;
        width: 100%;
        font-size: 14px; }
        header .header .account .account-text a:nth-child(2) {
          font-size: 14px;
          font-weight: 400; }
      header .header .account .cart-button {
        position: relative;
        min-width: 65px;
        height: 45px;
        border-radius: 6px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2px;
        float: right;
        margin: 0; }
        @media only screen and (max-width: 991px) {
          header .header .account .cart-button {
            background-color: #FFFFFF;
            height: 37px;
            padding: 0; } }
        header .header .account .cart-button .number {
          position: absolute;
          top: 6px;
          right: 10px;
          border-radius: 50%;
          background-color: #F7617E;
          color: #FFFFFF;
          width: 20px;
          height: 20px;
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media only screen and (max-width: 991px) {
            header .header .account .cart-button .number {
              display: none; } }
        header .header .account .cart-button svg {
          fill: none;
          stroke: #222222;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px; }
          @media only screen and (max-width: 991px) {
            header .header .account .cart-button svg {
              stroke: #333333; } }

.mobile-left-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(-110%, 0, 0);
  -moz-transform: translate3d(-110%, 0, 0);
  transform: translate3d(-110%, 0, 0); }
  .mobile-left-menu .content {
    width: 300px;
    height: 100%;
    background-color: #FFFFFF;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
    -moz-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0);
    -webkit-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    cursor: pointer; }
    .mobile-left-menu .content .user-area {
      width: 100%;
      height: 160px;
      background-color: #F7617E;
      padding: 30px 30px;
      position: relative; }
      .mobile-left-menu .content .user-area .currency-mobile {
        position: absolute;
        top: 16px;
        right: 16px; }
        .mobile-left-menu .content .user-area .currency-mobile a {
          margin: 0 5px;
          color: #FFFFFF; }
      .mobile-left-menu .content .user-area .user-image {
        width: 64px;
        height: 64px;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .mobile-left-menu .content .user-area .user-image svg {
          fill: #FFFFFF; }
      .mobile-left-menu .content .user-area p {
        color: #FFFFFF;
        margin-bottom: 0;
        font-size: 14px; }
        .mobile-left-menu .content .user-area p:nth-child(2) {
          font-weight: 600; }
        .mobile-left-menu .content .user-area p a {
          color: #FFFFFF; }
    .mobile-left-menu .content ul {
      padding: 25px;
      list-style: none; }
      .mobile-left-menu .content ul > li {
        padding: 8px 0;
        font-weight: 600;
        color: #333333; }
        .mobile-left-menu .content ul > li a {
          color: #333333;
          display: flex;
          align-items: center; }
        .mobile-left-menu .content ul > li svg {
          height: 24px;
          width: 24px;
          margin-right: 10px; }
        .mobile-left-menu .content ul > li i {
          font-size: 24px;
          margin-right: 10px; }

.mobile-left-menu-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .mobile-left-menu-open .content {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.mobile-right-menu {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(110%, 0, 0);
  -moz-transform: translate3d(110%, 0, 0);
  transform: translate3d(110%, 0, 0);
  cursor: pointer; }
  .mobile-right-menu .content {
    width: 300px;
    height: 100%;
    float: right;
    background-color: #FFFFFF;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
    -moz-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    -webkit-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    position: relative; }
    .mobile-right-menu .content h3 {
      background-color: #F7617E;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .mobile-right-menu .content .close {
      position: absolute;
      top: 6px;
      right: 6px;
      color: #FFFFFF;
      font-size: 32px; }
      .mobile-right-menu .content .close svg {
        fill: #FFFFFF; }
    .mobile-right-menu .content > ul {
      list-style: none;
      padding: 20px 16px;
      margin: 0;
      border-top: none;
      float: left;
      width: 100%;
      height: 100%;
      overflow: auto; }
      .mobile-right-menu .content > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left; }
        .mobile-right-menu .content > ul > li::after {
          /* content: " ";
                    display: block;
                    border-top: 2px solid $gray;
                    border-right: 2px solid $gray;
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                    float: right;
                    margin-top: 16px;
                    margin-right: 4px;*/ }
        .mobile-right-menu .content > ul > li a {
          float: left;
          color: #333333;
          padding: 7px 0; }
        .mobile-right-menu .content > ul > li > a {
          font-size: 18px; }
        .mobile-right-menu .content > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .mobile-right-menu .content > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #F7617E;
            border-left: 2px solid #F7617E;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .mobile-right-menu .content > ul > li ul li {
            width: 100%;
            float: left; }
      .mobile-right-menu .content > ul .active {
        position: relative; }
        .mobile-right-menu .content > ul .active::after {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(135deg); }

.mobile-right-menu-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .mobile-right-menu-open .content {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.footer {
  position: relative;
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 32px;
  width: 100%;
  float: left;
  background-color: #FFF2FE; }
  .footer .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 30px; }
    @media only screen and (max-width: 768px) {
      .footer .footer-links {
        flex-direction: column; } }
    .footer .footer-links li {
      font-size: 14px;
      padding: 0 30px; }
      @media only screen and (max-width: 768px) {
        .footer .footer-links li {
          padding: 5px 0; } }
      .footer .footer-links li a {
        color: #333333; }
  .footer .footer-content {
    text-align: center; }
    .footer .footer-content p {
      color: #333333;
      font-size: 16px;
      margin-bottom: 27px;
      margin-top: 49px; }
    .footer .footer-content .social {
      margin-bottom: 40px; }
      .footer .footer-content .social a {
        margin: 0 10px; }
  .footer .spacer {
    width: 100%;
    float: left;
    height: 1px;
    background: #333333;
    position: relative;
    margin: 0 15px;
    margin-bottom: 35px; }
  .footer .copyright {
    text-align: center;
    width: 100%; }
    .footer .copyright p {
      text-align: center;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 400;
      color: #333333; }

.select-field {
  border: 1px solid #D1D1D1;
  background-color: #FFFFFF;
  color: #FFFFFF;
  font-size: 14px; }
  .select-field a {
    color: #333333; }
  .select-field .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow: auto; }
  .select-field .dropdown-toggle::after {
    display: none; }
  .select-field .dropdown-toggle::before {
    content: " ";
    display: block;
    border-top: 2px solid #333333;
    border-right: 2px solid #333333;
    width: 10px;
    height: 10px;
    transform: rotate(135deg);
    float: right;
    margin-top: 6px; }

.input-wrap {
  width: 100%; }
  .input-wrap input[type=text], .input-wrap input[type=email], .input-wrap input[type=password] {
    height: 36px;
    width: 100%;
    border: 1px solid #D1D1D1;
    padding: 0 16px; }
  .input-wrap textarea {
    height: 150px;
    width: 100%;
    max-width: 500px;
    border: 1px solid #D1D1D1;
    padding: 0 16px; }

.input-wrap {
  margin-bottom: 12px; }
  .input-wrap label {
    width: 100%;
    color: #333333;
    font-size: 14px;
    margin-bottom: 2px; }
    .input-wrap label span {
      font-size: 12px; }
  .input-wrap .required {
    border: 2px solid #DB3838 !important; }
  .input-wrap .required-label {
    color: #DB3838; }
  .input-wrap .select-field {
    width: 100%;
    max-width: 540px;
    height: 36px; }
    .input-wrap .select-field .dropdown-toggle {
      height: 36px !important; }
  .input-wrap .checkbox {
    border: 2px solid #D1D1D1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;
    float: left; }
  .input-wrap .checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .checked::after {
      content: " ";
      display: block;
      border-right: 3px solid #F7617E;
      border-bottom: 3px solid #F7617E;
      width: 6px;
      height: 12px;
      transform: rotate(45deg); }
  .input-wrap .radio-button {
    border: 2px solid #D1D1D1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 50%;
    float: left; }
  .input-wrap .radio-button-checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .radio-button-checked::after {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #F7617E;
      border-radius: 50%; }

.radio-button-wrap {
  display: flex;
  align-items: center;
  cursor: pointer; }

.required-checkbox .checkbox {
  border-color: #DB3838 !important; }

.required-checkbox span {
  color: #DB3838 !important; }

.sort-form {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media only screen and (max-width: 768px) {
    .sort-form {
      justify-content: space-between;
      padding-bottom: 15px; } }
  .sort-form > span {
    font-size: 14px;
    margin-right: 12px; }
  .sort-form .select-field {
    height: 40px;
    width: 200px;
    margin-right: 28px; }
    @media only screen and (max-width: 768px) {
      .sort-form .select-field {
        margin-right: 0; } }
  .sort-form .active {
    background-color: #F7617E;
    border-radius: 0; }
  @media only screen and (max-width: 768px) {
    .sort-form .btn {
      display: none; } }

.newsletter-form {
  font-size: 18px; }
  .newsletter-form .button {
    margin-right: 16px; }

.article {
  text-decoration: none !important; }
  .article article {
    background-color: #FFFFFF;
    /*-webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);*/
    margin-bottom: 30px;
    min-height: 345px; }
    @media only screen and (max-width: 768px) {
      .article article {
        min-height: 198px;
        margin-bottom: 15px; } }
    .article article img {
      padding: 0;
      width: 100%;
      height: 255px;
      object-fit: contain; }
      @media only screen and (max-width: 768px) {
        .article article img {
          height: 100%; } }
    .article article .top {
      background-color: #F7617E;
      position: absolute;
      top: 0;
      right: 0;
      border-bottom-left-radius: 10px;
      color: #FFFFFF;
      padding: 4px 12px;
      font-size: 16px;
      font-weight: 500; }
    .article article .new {
      background-color: #F7617E;
      position: absolute;
      top: 0;
      right: 0;
      border-bottom-left-radius: 10px;
      color: #FFFFFF;
      padding: 4px 12px;
      font-size: 16px;
      font-weight: 500; }
    .article article h6 {
      margin: 14px 20px 11px 20px;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #333333;
      text-decoration: none !important;
      height: 44px;
      overflow: hidden; }
      @media only screen and (max-width: 768px) {
        .article article h6 {
          font-size: 16px;
          margin: 14px 10px 10px 10px;
          height: 42px; } }
    .article article p {
      padding: 0 20px 12px 20px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none !important;
      font-size: 18px;
      font-weight: 600;
      color: #F7617E;
      text-align: center;
      justify-content: center; }
      @media only screen and (max-width: 768px) {
        .article article p {
          padding: 0 10px 10px 10px;
          font-size: 16px; } }
      .article article p .old-price {
        color: #CCCCCC;
        text-decoration: line-through !important;
        margin-right: 8px;
        font-size: 16px; }
        @media only screen and (max-width: 767px) {
          .article article p .old-price {
            font-size: 11px;
            margin-right: 4px; } }
      .article article p .package {
        font-size: 16px; }
    .article article button {
      background-color: #F7617E;
      width: 100%;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 500;
      border: 0;
      outline: none;
      border-radius: 6px;
      padding: 8px 0; }

@media only screen and (max-width: 768px) {
  .article-container:nth-child(2n) {
    padding-left: 7.5px; }
  .article-container:nth-child(2n+1) {
    padding-right: 7.5px; } }

@media only screen and (max-width: 768px) {
  .article-container1:nth-child(2n) {
    padding-right: 7.5px; }
  .article-container1:nth-child(2n+1) {
    padding-left: 7.5px; } }

.list-article {
  text-decoration: none !important; }
  .list-article article {
    background-color: #FFFFFF;
    margin-bottom: 15px;
    width: 100%;
    height: 160px;
    display: flex; }
    .list-article article:hover {
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.2s ease-in-out; }
    .list-article article img {
      width: 200px;
      max-width: 200px;
      min-width: 200px;
      height: 160px;
      object-fit: contain;
      float: left;
      padding: 16px; }
    .list-article article > div {
      float: left;
      padding: 16px;
      width: 100%;
      position: relative; }
      .list-article article > div h6 {
        margin: 14px 20px 11px 20px;
        font-size: 22px;
        font-weight: 600;
        color: #333333;
        text-decoration: none !important;
        height: 44px;
        overflow: hidden; }
      .list-article article > div p {
        position: absolute;
        bottom: 16px;
        width: 100%;
        padding: 0 40px 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none !important;
        margin-bottom: 0; }
        .list-article article > div p .price {
          color: #F7617E;
          font-size: 18px;
          font-weight: 600; }
        .list-article article > div p .in-stock {
          margin-left: auto;
          font-size: 16px;
          color: #333333;
          display: flex;
          align-items: center; }
          .list-article article > div p .in-stock::after {
            content: " ";
            display: block;
            background-color: #97EF9D;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            float: right;
            margin-left: 10px; }

.alert {
  background-color: #DDF9EB;
  border: 1px solid #F7617E;
  font-size: 18px;
  min-height: 50px;
  margin-bottom: 34px;
  padding: 10px 18px;
  border-radius: 6px; }

.car-animation {
  position: relative;
  animation-name: car-animation;
  animation-duration: 2s; }

@keyframes car-animation {
  from {
    top: -50px;
    right: -80px;
    transform: scale(0.7); }
  to {
    top: 0px;
    right: 0;
    transform: scale(1); } }

.top-bottom-animation {
  position: relative;
  animation-name: top-bottom;
  animation-duration: 1s; }

@keyframes top-bottom {
  from {
    top: -200px; }
  to {
    top: 0px; } }

.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s; }

@keyframes dropdown-animation {
  from {
    transform: translateY(60px);
    opacity: 0; }
  to {
    transform: translateY(48px);
    opacity: 1; } }

@keyframes bubble-animation {
  0% {
    border: 10px solid rgba(255, 255, 255, 0); }
  50% {
    border: 20px solid rgba(255, 255, 255, 0.5); }
  100% {
    border: 10px solid rgba(255, 255, 255, 0); } }

.bubble-animation {
  animation-iteration-count: infinite;
  animation-name: bubble-animation;
  animation-duration: 2s; }

@keyframes active-menu-animation {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(70%, 10vh, 0); } }

.active-menu-animation {
  transform: translate3d(70%, 10vh, 0);
  position: fixed;
  width: 100% !important;
  position: fixed !important;
  height: 80vh !important;
  overflow-x: auto;
  overflow-y: scroll;
  animation-name: active-menu-animation;
  animation-duration: 1s;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16); }

.home-wrap .main {
  padding-top: 40px;
  padding-bottom: 20px; }
  .home-wrap .main h1 {
    display: none; }

.home-wrap .categories {
  background-color: #FFFFFF;
  float: left;
  width: 100%; }
  .home-wrap .categories .submenu {
    display: none; }
  .home-wrap .categories h3 {
    border-radius: 10px 10px 0 0;
    background-color: #F7617E;
    color: #FFFFFF;
    padding: 15px 22px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0; }
  .home-wrap .categories > ul {
    min-height: 385px; }
  .home-wrap .categories ul {
    border-radius: 0 0 10px 10px;
    width: 100%;
    list-style: none;
    padding: 7px 16px;
    margin: 0;
    border: 3px solid #F7617E;
    border-top: none;
    float: left; }
    .home-wrap .categories ul > li {
      width: 100%;
      float: left;
      border-bottom: 1px solid #CCCCCC; }
      .home-wrap .categories ul > li::after {
        content: " ";
        display: block;
        border-top: 2px solid #CCCCCC;
        border-right: 2px solid #CCCCCC;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        float: right;
        margin-top: 16px;
        margin-right: 5px; }
      .home-wrap .categories ul > li:last-child {
        border-bottom: 0; }
      .home-wrap .categories ul > li > a {
        float: left;
        color: #333333;
        padding: 8px 0;
        /*width: 100%;
                    border-bottom: 1px solid $gray;
                    &:last-child {
                        border-bottom: none;
                    }*/ }
      .home-wrap .categories ul > li:hover .submenu {
        border-radius: 0 10px 10px 0;
        height: 100%;
        position: absolute;
        left: 250px;
        background: white;
        z-index: 16;
        top: 0;
        padding-right: 40px;
        border-top: 3px solid #000;
        border-bottom: 3px solid #000;
        border-right: 3px solid #000;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-width: 875px; }
        .home-wrap .categories ul > li:hover .submenu h5 {
          font-size: 22px;
          font-weight: 600;
          line-height: 26px;
          color: #333333;
          margin-left: 13px;
          margin-top: 15px; }
        .home-wrap .categories ul > li:hover .submenu li {
          margin-left: 13px;
          float: none;
          display: flex;
          justify-content: space-between;
          width: 236px; }
          .home-wrap .categories ul > li:hover .submenu li:after {
            margin-right: 0px; }
          .home-wrap .categories ul > li:hover .submenu li:last-child {
            border-bottom: 1px solid #CCCCCC;
            margin-bottom: 14px; }
        .home-wrap .categories ul > li:hover .submenu ul {
          border: none; }

.home-wrap .home-slider {
  overflow: hidden;
  background-color: #FFFFFF; }
  .home-wrap .home-slider .carousel-control-prev {
    background-color: rgba(255, 255, 255, 0.3);
    width: 46px;
    height: 46px;
    position: absolute;
    left: 40px;
    top: 50%;
    margin-top: -23px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; }
    @media only screen and (max-width: 767px) {
      .home-wrap .home-slider .carousel-control-prev {
        left: 15px; } }
    .home-wrap .home-slider .carousel-control-prev span {
      display: none; }
    .home-wrap .home-slider .carousel-control-prev::after {
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      border-top: 2px solid #333333;
      border-left: 2px solid #333333;
      transform: rotate(-45deg); }
  .home-wrap .home-slider .carousel-control-next {
    background-color: rgba(255, 255, 255, 0.3);
    width: 46px;
    height: 46px;
    position: absolute;
    right: 40px;
    top: 50%;
    margin-top: -23px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; }
    @media only screen and (max-width: 767px) {
      .home-wrap .home-slider .carousel-control-next {
        right: 15px; } }
    .home-wrap .home-slider .carousel-control-next span {
      display: none; }
    .home-wrap .home-slider .carousel-control-next::after {
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      border-bottom: 2px solid #333333;
      border-right: 2px solid #333333;
      transform: rotate(-45deg); }
  .home-wrap .home-slider .carousel-indicators {
    left: 0;
    justify-content: center;
    margin-right: 0;
    margin-left: 50px; }
    @media only screen and (max-width: 767px) {
      .home-wrap .home-slider .carousel-indicators {
        margin-left: 20px;
        margin-bottom: 0px;
        display: none; } }
    .home-wrap .home-slider .carousel-indicators li {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #CCCCCC; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-indicators li {
          width: 8px;
          height: 8px; } }
    .home-wrap .home-slider .carousel-indicators .active {
      border: none;
      background-color: #F7617E; }
  .home-wrap .home-slider .carousel-item img {
    width: 100%;
    background-color: #F7617E; }
    @media only screen and (max-width: 767px) {
      .home-wrap .home-slider .carousel-item img {
        object-fit: cover; } }
  .home-wrap .home-slider .carousel-item .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 58px 50px; }
    @media only screen and (max-width: 767px) {
      .home-wrap .home-slider .carousel-item .content {
        padding: 20px; } }
    .home-wrap .home-slider .carousel-item .content .content-col {
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-item .content .content-col {
          margin-top: 36px; } }
    .home-wrap .home-slider .carousel-item .content h6 {
      color: #FFFFFF;
      font-weight: 400;
      font-family: 'Nanum Brush Script', cursive;
      font-size: 32px;
      text-align: center;
      margin-bottom: 0; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-item .content h6 {
          font-size: 12px; } }
    .home-wrap .home-slider .carousel-item .content h3 {
      color: #FFFFFF;
      font-size: 42px;
      font-weight: 700;
      margin: 0 auto;
      margin-bottom: 11px;
      max-width: 368px;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-item .content h3 {
          font-size: 16px;
          margin-bottom: 16px;
          max-width: 150px; } }
    .home-wrap .home-slider .carousel-item .content p {
      color: #FFFFFF;
      font-size: 18px;
      margin: 0 auto;
      margin-bottom: 26px;
      max-width: 310px;
      text-align: center; }
      @media only screen and (max-width: 1119px) {
        .home-wrap .home-slider .carousel-item .content p {
          display: none; } }
    .home-wrap .home-slider .carousel-item .content .button {
      color: #FFFFFF;
      background-color: transparent;
      border-radius: 0;
      padding: 15px 28px;
      text-decoration: none;
      font-size: 20px;
      font-weight: 500;
      border: 2px solid #FFFFFF; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-item .content .button {
          font-size: 12px;
          padding: 8px; } }

.home-wrap .banner {
  margin-top: 30px; }
  @media only screen and (max-width: 767px) {
    .home-wrap .banner:nth-child(2n+1) {
      padding-right: 5px; }
    .home-wrap .banner:nth-child(2n) {
      padding-left: 5px; } }
  .home-wrap .banner > div {
    border-radius: 10px;
    border: 1px solid #CCCCCC;
    background-color: #FFFFFF;
    float: left;
    height: 130px; }
    @media only screen and (max-width: 767px) {
      .home-wrap .banner > div {
        height: 120px; } }
    @media only screen and (min-width: 768px) and (max-width: 900px) {
      .home-wrap .banner > div {
        height: 160px; } }
    .home-wrap .banner > div .content {
      float: left;
      width: 50%;
      padding: 16px 0 16px 30px; }
      @media only screen and (max-width: 767px) {
        .home-wrap .banner > div .content {
          padding: 8px 0 8px 8px;
          width: 70%; } }
      .home-wrap .banner > div .content h3 {
        font-weight: 600;
        font-size: 22px;
        color: #333333;
        margin-bottom: 0; }
        @media only screen and (max-width: 1119px) {
          .home-wrap .banner > div .content h3 {
            font-size: 14px; } }
      .home-wrap .banner > div .content h6 {
        font-size: 22px;
        color: #333333;
        margin-bottom: 15px; }
        @media only screen and (max-width: 1119px) {
          .home-wrap .banner > div .content h6 {
            font-size: 12px; } }
      @media only screen and (max-width: 1119px) {
        .home-wrap .banner > div .content .button {
          padding: 6px;
          font-size: 14px; } }
    .home-wrap .banner > div img {
      float: left;
      width: 50%;
      height: 100%;
      object-fit: contain; }
      @media only screen and (max-width: 767px) {
        .home-wrap .banner > div img {
          width: 30%; } }

.home-wrap .section-articles a {
  text-decoration: none; }

.home-wrap .section-reviews .reviews-slider {
  background-color: #F7617E;
  padding-top: 30px;
  padding-bottom: 30px; }
  .home-wrap .section-reviews .reviews-slider .carousel-indicators li {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: transparent; }
  .home-wrap .section-reviews .reviews-slider .carousel-indicators .active {
    border: none;
    background-color: #FFFFFF; }
  .home-wrap .section-reviews .reviews-slider .review img {
    width: 160px;
    height: 160px;
    border-radius: 50%; }
  .home-wrap .section-reviews .reviews-slider .review p {
    color: #FFFFFF;
    margin-top: 11px;
    font-size: 18px; }
  .home-wrap .section-reviews .reviews-slider .review .user {
    margin-top: 32px;
    font-size: 20px;
    font-weight: 600; }

.home-wrap .section-news {
  background-color: #FFEBEB;
  padding-top: 50px;
  padding-bottom: 80px;
  margin-bottom: 0; }
  .home-wrap .section-news a {
    text-decoration: none; }
  .home-wrap .section-news .news-title-container a {
    color: #F7617E; }
  .home-wrap .section-news article {
    text-align: center;
    /*-webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);*/
    background-color: #FFFFFF;
    padding-bottom: 24px;
    height: 100%; }
    .home-wrap .section-news article img {
      width: 100%;
      height: 200px;
      margin-bottom: 18px; }
    .home-wrap .section-news article h6 {
      font-size: 22px;
      font-weight: 700;
      color: #333333;
      margin-bottom: 14px; }
    .home-wrap .section-news article P {
      font-size: 16px;
      color: #333333;
      margin-bottom: 20px;
      padding: 0 30px; }
    .home-wrap .section-news article a {
      color: #F7617E;
      text-decoration: none;
      font-size: 16spx;
      font-weight: 500; }

.slider-container {
  position: relative;
  padding-right: 0;
  padding-left: 0; }
  .slider-container .slick-arrow {
    background-color: #FFFFFF;
    outline: none;
    border: 0;
    color: transparent;
    width: 36px;
    height: 36px;
    position: absolute; }
    @media only screen and (max-width: 767px) {
      .slider-container .slick-arrow {
        display: none !important; } }
  .slider-container .slick-next {
    top: -58px;
    right: 15px; }
    .slider-container .slick-next::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333333;
      border-left: 2px solid #333333;
      width: 12px;
      height: 12px;
      transform: rotate(135deg);
      position: absolute;
      top: 12px;
      left: 12px; }
  .slider-container .slick-prev {
    top: -58px;
    right: 67px; }
    .slider-container .slick-prev::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333333;
      border-left: 2px solid #333333;
      width: 12px;
      height: 12px;
      transform: rotate(-45deg);
      position: absolute;
      top: 12px;
      left: 15px; }
  .slider-container .slick-slider {
    overflow: hidden; }
  .slider-container .slick-list .slick-slide {
    float: left; }
    .slider-container .slick-list .slick-slide > div {
      padding: 0 15px; }

.brands-container {
  position: relative;
  padding-right: 0;
  padding-left: 0; }
  .brands-container .slick-arrow {
    display: none !important; }
  .brands-container .slick-slider {
    overflow: hidden; }
  .brands-container .slick-list .slick-slide {
    float: left; }
    .brands-container .slick-list .slick-slide > div {
      padding: 0 15px; }
  .brands-container .brand img {
    width: 100%;
    filter: grayscale(100%); }
  .brands-container .brand:hover img {
    filter: none; }

.section-banners img {
  width: 100%;
  object-fit: cover;
  margin-bottom: 15px; }

.section-categories {
  padding: 30px 0;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .section-categories {
      padding: 20px 0;
      margin-bottom: 20px; } }
  .section-categories .category-box {
    text-align: center; }
    .section-categories .category-box img {
      width: 100%;
      height: 77px;
      object-position: center;
      object-fit: contain; }
      @media only screen and (max-width: 767px) {
        .section-categories .category-box img {
          border-radius: 50%;
          height: 70px; } }
    .section-categories .category-box a {
      text-decoration: none !important; }

.news-item {
  margin-bottom: 30px; }
  .news-item a {
    text-decoration: none; }
  .news-item article {
    text-align: center;
    /*-webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);*/
    background-color: #FFFFFF;
    padding-bottom: 24px; }
    .news-item article img {
      width: 100%;
      height: 200px;
      margin-bottom: 18px; }
    .news-item article h6 {
      font-size: 22px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 14px; }
    .news-item article P {
      font-size: 18px;
      color: #333333;
      margin-bottom: 20px;
      padding: 0 30px; }
    .news-item article a {
      color: #F7617E;
      text-decoration: none;
      font-size: 20px;
      font-weight: 300; }

.mobile-currency {
  display: none;
  position: fixed;
  right: 0;
  bottom: 50px;
  background-color: #F7617E;
  border-radius: 6px 0 0 6px;
  padding: 12px 12px;
  color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .mobile-currency {
      display: block; } }
  .mobile-currency a {
    color: #FFFFFF;
    font-weight: 600;
    margin: 0 5px; }

.blog-wrap {
  background-color: #FFEBEB; }

.category-wrap {
  padding-top: 20px; }
  .category-wrap .category-title {
    margin-bottom: 48px; }
    @media only screen and (max-width: 767px) {
      .category-wrap .category-title {
        margin-bottom: 0px; } }
    .category-wrap .category-title h2 {
      font-size: 32px;
      font-weight: 700;
      color: #333333;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .category-wrap .category-title h2 {
          font-size: 21px; } }
  .category-wrap .categories-list {
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .category-wrap .categories-list {
        margin-bottom: 10px; } }
    .category-wrap .categories-list ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0; }
      @media only screen and (max-width: 767px) {
        .category-wrap .categories-list ul {
          flex-direction: row;
          flex-wrap: wrap; } }
      .category-wrap .categories-list ul li {
        margin: 0 25px;
        border-bottom: 2px solid transparent;
        position: relative; }
        @media only screen and (max-width: 767px) {
          .category-wrap .categories-list ul li {
            text-align: center;
            width: 50%;
            margin: 0;
            margin: 5px 0; }
            .category-wrap .categories-list ul li:first-child {
              width: 100%; } }
        .category-wrap .categories-list ul li a {
          font-size: 20px;
          font-weight: 700;
          color: #333333;
          text-decoration: none; }
        .category-wrap .categories-list ul li:hover {
          border-bottom: 2px solid #F7617E; }
          .category-wrap .categories-list ul li:hover a {
            color: #333333; }
        .category-wrap .categories-list ul li .categories-dropdown {
          position: absolute;
          z-index: 9999;
          background-color: #FFFFFF;
          -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
          padding: 10px;
          display: none;
          flex-direction: column;
          min-width: 250px; }
          .category-wrap .categories-list ul li .categories-dropdown a {
            font-size: 12px;
            padding: 3px 0;
            font-weight: 500; }
            .category-wrap .categories-list ul li .categories-dropdown a:hover {
              color: #F7617E; }
        .category-wrap .categories-list ul li:hover .categories-dropdown {
          display: flex; }
      .category-wrap .categories-list ul .active {
        border-bottom: 2px solid #F7617E; }
        .category-wrap .categories-list ul .active a {
          color: #333333; }
  .category-wrap .pagination {
    float: left; }
    .category-wrap .pagination > li {
      width: 40px;
      height: 40px;
      background-color: #E5E5E5;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333333;
      font-weight: 600;
      font-size: 16px;
      margin-right: 10px;
      cursor: pointer; }
      .category-wrap .pagination > li a {
        color: #333333;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .category-wrap .pagination > li:hover {
        color: #FFFFFF;
        text-decoration: none;
        background-color: #F7617E; }
        .category-wrap .pagination > li:hover a {
          text-decoration: none;
          color: #FFFFFF; }
    .category-wrap .pagination .break-me:hover {
      background-color: #E5E5E5;
      cursor: default; }
      .category-wrap .pagination .break-me:hover a {
        color: #333333; }
    .category-wrap .pagination .active {
      color: #FFFFFF;
      background-color: #F7617E; }
      .category-wrap .pagination .active a {
        color: #FFFFFF; }
    .category-wrap .pagination .previous {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .category-wrap .pagination .previous::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 2px solid #333333;
        border-left: 2px solid #333333;
        padding: 4px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 16px;
        left: 16px; }
      .category-wrap .pagination .previous:hover::after {
        border-bottom: 2px solid #FFFFFF;
        border-left: 2px solid #FFFFFF; }
    .category-wrap .pagination .next {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center; }
      .category-wrap .pagination .next::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 2px solid #333333;
        border-left: 2px solid #333333;
        padding: 4px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 16px;
        left: 16px; }
      .category-wrap .pagination .next:hover::after {
        border-bottom: 2px solid #FFFFFF;
        border-left: 2px solid #FFFFFF; }
  @media only screen and (max-width: 768px) {
    .category-wrap .articles {
      padding: 0; } }
  .category-wrap .categories {
    background-color: #FFFFFF;
    float: left;
    width: 100%; }
    .category-wrap .categories h3 {
      background-color: #333333;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .category-wrap .categories > ul {
      width: 100%;
      list-style: none;
      padding: 3px 16px;
      margin: 0;
      border: 1px solid #333333;
      border-top: none;
      float: left;
      width: 100%; }
      .category-wrap .categories > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left; }
        .category-wrap .categories > ul > li::after {
          content: " ";
          display: block;
          border-top: 2px solid #CCCCCC;
          border-right: 2px solid #CCCCCC;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          float: right;
          margin-top: 16px;
          margin-right: 4px; }
        .category-wrap .categories > ul > li:last-child {
          border-bottom: none; }
        .category-wrap .categories > ul > li a {
          float: left;
          color: #333333;
          padding: 7px 0; }
        .category-wrap .categories > ul > li > a {
          font-size: 18px; }
        .category-wrap .categories > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .category-wrap .categories > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #F7617E;
            border-left: 2px solid #F7617E;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .category-wrap .categories > ul > li ul li {
            width: 100%;
            float: left; }
      .category-wrap .categories > ul .active {
        position: relative; }
        .category-wrap .categories > ul .active::after {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(135deg); }

.detail-wrap {
  padding-top: 20px; }
  .detail-wrap h1 {
    font-size: 32px;
    font-weight: 600; }
  .detail-wrap .shipping-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; }
    .detail-wrap .shipping-modal > div {
      padding: 50px 15px;
      width: 900px;
      height: 90%;
      background-color: #FFFFFF;
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      position: relative; }
      @media only screen and (max-width: 767px) {
        .detail-wrap .shipping-modal > div {
          width: 100%;
          height: 100%; } }
      .detail-wrap .shipping-modal > div > div {
        overflow-y: scroll;
        height: 100%; }
      .detail-wrap .shipping-modal > div .close {
        position: absolute;
        top: 15px;
        right: 10px;
        font-size: 32px; }
  .detail-wrap .package-dropdown > button {
    width: 100%;
    text-align: left;
    border: 2px solid #F7617E;
    border-radius: 6px;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    padding: 14px 20px;
    font-weight: 600; }
    .detail-wrap .package-dropdown > button::after {
      content: ' ';
      display: block;
      width: 16px;
      height: 16px;
      border-bottom: 2px solid #F7617E;
      border-right: 2px solid #F7617E;
      transform: rotate(45deg);
      margin-top: -5px; }
  .detail-wrap .package-dropdown .dropdown-menu {
    width: 100%; }
    .detail-wrap .package-dropdown .dropdown-menu button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px; }
      .detail-wrap .package-dropdown .dropdown-menu button span {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: #F7617E; }
        .detail-wrap .package-dropdown .dropdown-menu button span span {
          color: #CCCCCC;
          text-decoration: line-through;
          font-size: 14px; }
  .detail-wrap .categories {
    background-color: #FFFFFF;
    float: left;
    width: 100%; }
    .detail-wrap .categories h3 {
      background-color: #333333;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .detail-wrap .categories > ul {
      width: 100%;
      list-style: none;
      padding: 3px 16px;
      margin: 0;
      border: 1px solid #333333;
      border-top: none;
      float: left;
      width: 100%; }
      .detail-wrap .categories > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left; }
        .detail-wrap .categories > ul > li::after {
          content: " ";
          display: block;
          border-top: 2px solid #CCCCCC;
          border-right: 2px solid #CCCCCC;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          float: right;
          margin-top: 16px;
          margin-right: 4px; }
        .detail-wrap .categories > ul > li:last-child {
          border-bottom: none; }
        .detail-wrap .categories > ul > li a {
          float: left;
          color: #333333;
          padding: 7px 0; }
        .detail-wrap .categories > ul > li > a {
          font-size: 18px; }
        .detail-wrap .categories > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .detail-wrap .categories > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #F7617E;
            border-left: 2px solid #F7617E;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .detail-wrap .categories > ul > li ul li {
            width: 100%;
            float: left; }
      .detail-wrap .categories > ul .active {
        position: relative; }
        .detail-wrap .categories > ul .active::after {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(135deg); }
  .detail-wrap .article-box {
    background-color: #FFFFFF;
    margin-bottom: 0;
    padding: 18px 45px 45px 45px;
    padding-bottom: 0; }
    @media only screen and (max-width: 768px) {
      .detail-wrap .article-box {
        padding: 15px;
        padding-bottom: 0;
        margin-bottom: 0; } }
    .detail-wrap .article-box ul {
      list-style: none; }
    .detail-wrap .article-box table {
      width: 100%;
      overflow-x: auto;
      max-width: 100%;
      border: 1px solid #F7617E; }
      .detail-wrap .article-box table tr th {
        background-color: #F7617E;
        padding: 16px;
        color: #FFFFFF; }
      .detail-wrap .article-box table tr td {
        border: 1px solid #F7617E;
        padding: 16px; }
    .detail-wrap .article-box .variations-table {
      max-width: 100%;
      overflow: auto; }
    .detail-wrap .article-box .variations table tr th {
      font-size: 12px;
      padding: 6px; }
    .detail-wrap .article-box .variations table tr td {
      font-size: 12px;
      padding: 6px; }
    .detail-wrap .article-box .category {
      color: #F7617E;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600; }
    .detail-wrap .article-box .shortDescription {
      font-size: 16px;
      color: #333333; }
      .detail-wrap .article-box .shortDescription ul {
        padding-left: 32px; }
        .detail-wrap .article-box .shortDescription ul li {
          position: relative; }
          .detail-wrap .article-box .shortDescription ul li::before {
            content: ' ';
            display: block;
            border-bottom: 2px solid #F7617E;
            border-right: 2px solid #F7617E;
            width: 7px;
            height: 12px;
            transform: rotate(45deg);
            position: absolute;
            top: 3px;
            left: -32px; }
    .detail-wrap .article-box h2 {
      font-size: 32px;
      font-weight: 700; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box h2 {
          font-size: 24px; } }
    .detail-wrap .article-box h3 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 19px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box h3 {
          font-size: 20px; } }
    .detail-wrap .article-box .stock {
      font-size: 18px;
      color: #333333;
      font-weight: 300;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .detail-wrap .article-box .stock .out-of-stock {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #DE3636;
        float: left;
        margin-right: 14px; }
      .detail-wrap .article-box .stock .in-stock {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #97EF9D;
        float: left;
        margin-right: 14px; }
    .detail-wrap .article-box .spacer {
      width: 100%;
      background-color: #CCCCCC;
      height: 1px;
      margin: 18px 0;
      float: left; }
    .detail-wrap .article-box .price {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between;
      color: #333333;
      font-size: 18px;
      font-weight: 600; }
      .detail-wrap .article-box .price p {
        margin-top: 10px;
        margin-bottom: 0; }
      .detail-wrap .article-box .price span {
        color: #F7617E;
        font-size: 36px;
        font-weight: 600;
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .detail-wrap .article-box .price span .old-price {
          font-size: 18px;
          color: #CCCCCC;
          text-decoration: line-through; }
    .detail-wrap .article-box .description-title {
      font-size: 28px;
      font-weight: 600;
      color: #333333; }
    .detail-wrap .article-box .description {
      font-size: 18px;
      color: #333333; }
    .detail-wrap .article-box .actions {
      float: left;
      width: 100%;
      margin-top: 12px;
      display: flex;
      flex-direction: column; }
      .detail-wrap .article-box .actions .wishlist-btn {
        background-color: #F7617E;
        border: 0;
        outline: none;
        height: 50px;
        min-width: 50px !important;
        margin-left: 20px;
        border-radius: 6px; }
      .detail-wrap .article-box .actions > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px; }
      .detail-wrap .article-box .actions .buy-buttons {
        margin-bottom: 10px; }
        .detail-wrap .article-box .actions .buy-buttons button {
          font-size: 16px; }
          .detail-wrap .article-box .actions .buy-buttons button:first-child {
            min-height: 58px; }
      .detail-wrap .article-box .actions .shipping-info {
        cursor: pointer;
        font-size: 18px;
        color: #F7617E;
        font-weight: 300;
        margin-bottom: 45px;
        text-decoration: underline; }
        @media only screen and (max-width: 768px) {
          .detail-wrap .article-box .actions .shipping-info {
            margin-bottom: 20px; } }
      .detail-wrap .article-box .actions .qunatity-wrap {
        background-color: #F5F5F5;
        padding: 20px; }
      .detail-wrap .article-box .actions .quantity-input {
        border: 2px solid #F7617E;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        width: 100%;
        border-radius: 6px;
        background-color: #FFFFFF; }
        .detail-wrap .article-box .actions .quantity-input > span {
          font-size: 18px; }
        .detail-wrap .article-box .actions .quantity-input > div {
          display: flex; }
          .detail-wrap .article-box .actions .quantity-input > div input {
            width: 30px;
            text-align: center;
            font-size: 18px;
            border: 0;
            outline: none; }
          .detail-wrap .article-box .actions .quantity-input > div .dec {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            outline: none;
            background-color: #FFFFFF; }
            .detail-wrap .article-box .actions .quantity-input > div .dec::after {
              content: ' ';
              display: block;
              width: 10px;
              height: 10px;
              border-top: 1px solid #333333;
              border-left: 1px solid #333333;
              transform: rotate(-45deg); }
          .detail-wrap .article-box .actions .quantity-input > div .inc {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            outline: none;
            background-color: #FFFFFF; }
            .detail-wrap .article-box .actions .quantity-input > div .inc::after {
              content: ' ';
              display: block;
              width: 10px;
              height: 10px;
              border-bottom: 1px solid #333333;
              border-right: 1px solid #333333;
              transform: rotate(-45deg); }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box .actions {
          margin-bottom: 0px; } }
      .detail-wrap .article-box .actions .button {
        font-size: 20px;
        width: 48%;
        border-radius: 6px;
        font-weight: 600;
        padding: 8px 20px; }
        .detail-wrap .article-box .actions .button:first-child {
          background-color: #F7617E; }
          @media only screen and (max-width: 768px) {
            .detail-wrap .article-box .actions .button:first-child {
              position: fixed;
              bottom: 120px;
              right: 0;
              z-index: 999;
              margin-bottom: 0;
              border-radius: 6px 0 0 6px;
              font-size: 22px; } }
        @media only screen and (max-width: 768px) {
          .detail-wrap .article-box .actions .button {
            margin-bottom: 16px;
            font-size: 14px; } }
    .detail-wrap .article-box .actions {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between; }
      .detail-wrap .article-box .actions .add-review {
        max-width: 170px;
        display: flex;
        align-items: center; }
        .detail-wrap .article-box .actions .add-review .button {
          float: left;
          padding: 10px;
          margin-right: 13px; }
        .detail-wrap .article-box .actions .add-review p {
          margin-bottom: 0; }
      .detail-wrap .article-box .actions .ask-question {
        color: #F7617E;
        font-size: 18px;
        text-decoration: underline;
        margin-top: auto; }

@media only screen and (max-width: 767px) {
  .article-image {
    margin-bottom: 15px; } }

.article-image .preview {
  width: 100%;
  height: 445px;
  padding: 0; }
  @media only screen and (max-width: 768px) {
    .article-image .preview {
      height: 300px; } }
  .article-image .preview img {
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .article-image .preview img {
        height: 300px;
        object-fit: contain; } }

.article-image .slide-show {
  display: flex;
  justify-content: space-between;
  width: 100%;
  float: left;
  margin-bottom: 52px; }
  @media only screen and (max-width: 767px) {
    .article-image .slide-show {
      flex-direction: column; }
      .article-image .slide-show .images {
        order: 1; }
      .article-image .slide-show .preview {
        order: 0; } }

.article-image .images {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  width: 160px;
  margin-right: 30px; }
  .article-image .images .image {
    height: 160px;
    margin-bottom: 30px; }
    .article-image .images .image img {
      width: 160px;
      height: 100%; }
  .article-image .images .slick-arrow {
    background-color: #FFFFFF;
    outline: none;
    border: 0;
    color: transparent;
    width: 20px;
    height: 20px;
    position: absolute; }
  .article-image .images .slick-next {
    bottom: 0;
    left: 70px; }
    .article-image .images .slick-next::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333333;
      border-left: 2px solid #333333;
      width: 21px;
      height: 21px;
      transform: rotate(-135deg);
      position: absolute;
      top: 0;
      left: 0; }
  .article-image .images .slick-prev {
    top: 0;
    left: 70px; }
    .article-image .images .slick-prev::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333333;
      border-left: 2px solid #333333;
      width: 21px;
      height: 21px;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      left: 0; }
  .article-image .images .slick-slider {
    overflow: hidden;
    height: 350px;
    margin-top: 40px; }
  .article-image .images .slick-list .slick-slide {
    float: left; }
  @media only screen and (max-width: 767px) {
    .article-image .images {
      position: relative;
      padding-right: 0;
      padding-left: 0;
      width: 100%;
      margin-right: 30px;
      padding: 0 30px; }
      .article-image .images .image {
        height: 50px;
        margin-bottom: 0; }
        .article-image .images .image img {
          width: 50px;
          height: 100%; }
      .article-image .images .slick-arrow {
        background-color: #FFFFFF;
        outline: none;
        border: 0;
        color: transparent;
        width: 20px;
        height: 20px;
        position: absolute; }
      .article-image .images .slick-next {
        bottom: unset;
        top: 50%;
        left: unset;
        right: 0;
        margin-top: 10px; }
        .article-image .images .slick-next::after {
          content: ' ';
          display: block;
          border-top: 2px solid #333333;
          border-left: 2px solid #333333;
          width: 21px;
          height: 21px;
          transform: rotate(135deg);
          position: absolute;
          top: 0;
          left: 0; }
      .article-image .images .slick-prev {
        top: 50%;
        left: 0px;
        margin-top: 10px; }
        .article-image .images .slick-prev::after {
          content: ' ';
          display: block;
          border-top: 2px solid #333333;
          border-left: 2px solid #333333;
          width: 21px;
          height: 21px;
          transform: rotate(-45deg);
          position: absolute;
          top: 0;
          left: 0; }
      .article-image .images .slick-slider {
        overflow: hidden;
        margin-top: 40px;
        height: unset; }
      .article-image .images .slick-list {
        width: 100%; }
        .article-image .images .slick-list .slick-track {
          width: 100%; }
        .article-image .images .slick-list .slick-slide {
          float: left; } }

.packages {
  margin: 0 -5px;
  margin-top: 12px;
  width: 100%;
  float: left;
  cursor: pointer; }
  .packages .package {
    border: 2px solid #333333;
    border-radius: 6px;
    float: left;
    padding: 10px 8px;
    margin: 0 5px;
    margin-bottom: 10px;
    width: 100%;
    padding-left: 40px;
    position: relative;
    font-weight: 600; }
    .packages .package::after {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      background-color: #FFFFFF;
      position: absolute;
      top: 30px;
      left: 10px;
      border-radius: 50%;
      border: 2px solid #cacaca; }
    .packages .package p {
      margin-bottom: 6px;
      color: #999999;
      font-size: 16px; }
      .packages .package p:nth-child(2) {
        margin-bottom: 0; }
  .packages .active {
    border: 2px solid #F7617E; }
    .packages .active p {
      margin-bottom: 6px;
      color: #333333;
      font-size: 16px; }
      .packages .active p:nth-child(2) {
        margin-bottom: 0;
        font-weight: 600;
        color: #F7617E; }
    .packages .active::after {
      content: ' ';
      display: block;
      width: 20px;
      height: 20px;
      background-color: #FFFFFF;
      position: absolute;
      top: 30px;
      left: 10px;
      border-radius: 50%;
      border: 2px solid #999; }
    .packages .active::before {
      content: ' ';
      width: 12px;
      height: 12px;
      display: block;
      background-color: #F7617E;
      position: absolute;
      top: 34px;
      left: 14px;
      z-index: 2;
      border-radius: 50%; }

.article-detail {
  min-height: 420px; }

.detail-section1 {
  width: 100%;
  float: left;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .detail-section1 {
      padding: 30px 15px; } }
  .detail-section1 .container-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    @media only screen and (max-width: 768px) {
      .detail-section1 .container-wrap {
        position: unset;
        top: unset;
        left: unset;
        padding-top: 160px; } }
    .detail-section1 .container-wrap .container {
      height: 100%; }
      .detail-section1 .container-wrap .container .row {
        height: 100%;
        align-items: center; }
  .detail-section1 > img {
    width: 100%; }
    @media only screen and (max-width: 768px) {
      .detail-section1 > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .detail-section1 h3 {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 30px; }
    @media only screen and (max-width: 768px) {
      .detail-section1 h3 {
        font-size: 30px;
        text-align: center; } }
  .detail-section1 .block {
    padding: 20px;
    background-color: #E6E7E3; }
    .detail-section1 .block p {
      font-size: 18px; }
    .detail-section1 .block h6 {
      color: #F7617E;
      font-size: 28px;
      font-weight: 600; }
    @media only screen and (max-width: 768px) {
      .detail-section1 .block {
        text-align: center !important; } }

.detail-section2 {
  width: 100%;
  float: left;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .detail-section2 {
      padding: 30px 15px; } }
  .detail-section2 .container-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    @media only screen and (max-width: 768px) {
      .detail-section2 .container-wrap {
        position: unset;
        top: unset;
        left: unset;
        padding-top: 160px; } }
    .detail-section2 .container-wrap .container {
      height: 100%; }
      .detail-section2 .container-wrap .container .row {
        height: 100%;
        align-items: center; }
  .detail-section2 > img {
    width: 100%; }
    @media only screen and (max-width: 768px) {
      .detail-section2 > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .detail-section2 h3 {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 30px; }
    @media only screen and (max-width: 768px) {
      .detail-section2 h3 {
        font-size: 30px;
        text-align: center; } }
  .detail-section2 .block {
    padding: 20px; }
    .detail-section2 .block p {
      font-size: 18px;
      color: #FFFFFF; }
    .detail-section2 .block h6 {
      color: #FFFFFF;
      font-size: 28px;
      font-weight: 600; }
    @media only screen and (max-width: 768px) {
      .detail-section2 .block {
        text-align: center !important; } }

.detail-section3 {
  width: 100%;
  float: left;
  position: relative;
  background-color: #FFDBDB;
  padding: 50px 0; }
  .detail-section3 .container-wrap {
    /*position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;*/ }
    .detail-section3 .container-wrap .container {
      height: 100%; }
      .detail-section3 .container-wrap .container .row {
        height: 100%;
        align-items: center; }
  .detail-section3 img {
    width: 100%; }
  .detail-section3 h3 {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 30px; }
    @media only screen and (max-width: 768px) {
      .detail-section3 h3 {
        font-size: 30px;
        text-align: center;
        margin-top: 50px; } }
  .detail-section3 .block p {
    font-size: 18px; }
  .detail-section3 .block h6 {
    color: #F7617E;
    font-size: 28px;
    font-weight: 600; }
  @media only screen and (max-width: 768px) {
    .detail-section3 .block {
      text-align: center !important; } }

.detail-section4 {
  width: 100%;
  float: left;
  position: relative;
  padding: 50px 0; }
  @media only screen and (max-width: 768px) {
    .detail-section4 {
      padding: 20px 0; } }
  .detail-section4 .container-wrap .container {
    height: 100%; }
    .detail-section4 .container-wrap .container .row {
      height: 100%;
      align-items: center; }
  .detail-section4 h3 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #F7617E;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .detail-section4 h3 {
        font-size: 24px;
        text-align: center; } }
  .detail-section4 h2 {
    text-align: center;
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 50px; }
    @media only screen and (max-width: 768px) {
      .detail-section4 h2 {
        font-size: 28px; } }
  .detail-section4 p {
    font-size: 18px;
    margin-bottom: 10px; }
  .detail-section4 img {
    margin-bottom: 15px;
    object-fit: contain;
    width: 100%;
    max-height: 150px; }
    @media only screen and (max-width: 768px) {
      .detail-section4 img {
        width: 100%;
        object-fit: contain;
        max-height: 150px;
        margin-bottom: 5px; } }
  .detail-section4 .block {
    padding: 20px;
    background-color: #E6E7E3; }
    .detail-section4 .block p {
      font-size: 18px; }
    .detail-section4 .block h6 {
      color: #F7617E;
      font-size: 28px;
      font-weight: 600; }

.detail-section5 {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 0; }
  @media only screen and (max-width: 768px) {
    .detail-section5 {
      padding: 30px 15px; } }
  .detail-section5 .container-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    @media only screen and (max-width: 768px) {
      .detail-section5 .container-wrap {
        position: unset;
        top: unset;
        left: unset;
        padding-top: 160px; } }
    .detail-section5 .container-wrap .container {
      height: 100%; }
      .detail-section5 .container-wrap .container .row {
        height: 100%;
        align-items: center; }
  .detail-section5 > img {
    width: 100%; }
    @media only screen and (max-width: 768px) {
      .detail-section5 > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .detail-section5 h3 {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #FFFFFF; }
    @media only screen and (max-width: 768px) {
      .detail-section5 h3 {
        font-size: 30px;
        text-align: center; } }
  .detail-section5 .block {
    padding: 20px;
    background-color: #E6E7E3; }
    .detail-section5 .block p {
      font-size: 18px; }
    .detail-section5 .block h6 {
      color: #F7617E;
      font-size: 28px;
      font-weight: 600; }

.comments {
  position: relative;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  float: left; }
  .comments .slick-slider {
    height: 400px; }
  .comments .slick-arrow {
    display: none !important; }
  .comments .image img {
    object-fit: contain;
    width: 100%; }
  .comments .slick-list .slick-slide {
    float: left; }

.composition-button {
  background-color: transparent;
  font-size: 14px;
  border: 0;
  outline: none !important;
  margin-bottom: 15px;
  padding: 0;
  display: flex;
  align-items: center; }
  .composition-button::after {
    content: ' ';
    width: 8px;
    height: 8px;
    display: block;
    border-left: 2px solid #333333;
    border-bottom: 2px solid #333333;
    transform: rotate(-45deg);
    margin-left: 10px;
    margin-top: -3px; }

.composition {
  margin-bottom: 20px;
  font-size: 14px; }

.variations-table {
  background-color: #F5F5F5;
  padding: 15px; }
  .variations-table > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 32px;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px; }
    @media only screen and (max-width: 767px) {
      .variations-table > div {
        font-size: 14px; } }
    .variations-table > div span {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      color: #F7617E; }
      @media only screen and (max-width: 767px) {
        .variations-table > div span {
          font-size: 14px; } }
      .variations-table > div span span {
        color: #CCCCCC;
        text-decoration: line-through;
        font-size: 14px;
        margin-right: 8px; }
        @media only screen and (max-width: 767px) {
          .variations-table > div span span {
            font-size: 14px; } }
    .variations-table > div::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 0;
      background-color: #fff;
      border: 2px solid #F7617E; }
    .variations-table > div.active::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      left: 5px;
      background-color: #F7617E; }

.account-wrap {
  padding-top: 40px; }
  .account-wrap h1 {
    font-size: 32px;
    font-weight: 600; }
  .account-wrap .categories {
    background-color: #FFFFFF;
    float: left;
    width: 100%; }
    .account-wrap .categories h3 {
      background-color: #333333;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .account-wrap .categories > ul {
      width: 100%;
      list-style: none;
      padding: 3px 16px;
      margin: 0;
      border: 1px solid #333333;
      border-top: none;
      float: left;
      width: 100%; }
      .account-wrap .categories > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left; }
        .account-wrap .categories > ul > li::after {
          content: " ";
          display: block;
          border-top: 2px solid #CCCCCC;
          border-right: 2px solid #CCCCCC;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          float: right;
          margin-top: 16px;
          margin-right: 4px; }
        .account-wrap .categories > ul > li:last-child {
          border-bottom: none; }
        .account-wrap .categories > ul > li a {
          float: left;
          color: #333333;
          padding: 7px 0; }
        .account-wrap .categories > ul > li > a {
          font-size: 18px; }
        .account-wrap .categories > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .account-wrap .categories > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #F7617E;
            border-left: 2px solid #F7617E;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .account-wrap .categories > ul > li ul li {
            width: 100%;
            float: left; }
      .account-wrap .categories > ul .active {
        position: relative; }
        .account-wrap .categories > ul .active::after {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(135deg); }
  .account-wrap .account-info p {
    font-size: 18px; }
  .account-wrap .box-container {
    background-color: #FFFFFF;
    margin-bottom: 30px;
    padding: 40px 0;
    padding-bottom: 26px;
    margin-top: 10px; }
    @media only screen and (max-width: 768px) {
      .account-wrap .box-container {
        padding: 15px 0;
        padding-bottom: 26px; } }
    .account-wrap .box-container h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 24px; }
    .account-wrap .box-container h6 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px; }
    .account-wrap .box-container .lost-password {
      color: #333333; }
    .account-wrap .box-container table {
      width: 100%;
      border: 1px solid #F7617E; }
      .account-wrap .box-container table tr th {
        background-color: #F7617E;
        padding: 16px;
        color: #FFFFFF; }
      .account-wrap .box-container table tr td {
        border: 1px solid #F7617E;
        padding: 16px; }
    .account-wrap .box-container .button-group {
      padding: 0 0 20px 0; }
      @media only screen and (max-width: 991px) {
        .account-wrap .box-container .button-group {
          padding: 0 15px; } }
      .account-wrap .box-container .button-group .button {
        margin-right: 20px; }
        @media only screen and (max-width: 991px) {
          .account-wrap .box-container .button-group .button {
            margin-right: 0;
            margin-bottom: 16px;
            width: 100%; } }
    .account-wrap .box-container .table {
      border: 1px solid #D1D1D1; }
      @media only screen and (max-width: 991px) {
        .account-wrap .box-container .table {
          margin: 0 15px;
          margin-bottom: 16px; } }
      .account-wrap .box-container .table .row > div {
        padding: 15px; }
        .account-wrap .box-container .table .row > div P {
          margin-bottom: 0;
          font-size: 18px; }
      .account-wrap .box-container .table .header {
        background-color: #F7617E;
        color: #FFFFFF;
        font-weight: 600;
        padding: 0;
        height: 34px; }
        .account-wrap .box-container .table .header > div {
          padding: 0 15px;
          display: flex;
          align-items: center;
          font-size: 14px; }
      .account-wrap .box-container .table .border-row {
        border-bottom: 1px solid #D1D1D1; }
        .account-wrap .box-container .table .border-row:last-child {
          border-bottom: 0; }
        .account-wrap .box-container .table .border-row > div {
          border-right: 1px solid #D1D1D1; }
          .account-wrap .box-container .table .border-row > div:last-child {
            border-right: 0; }
    .account-wrap .box-container .spacer {
      width: 100%;
      background-color: #CCCCCC;
      height: 1px;
      margin: 18px 0;
      float: left; }
    .account-wrap .box-container a {
      text-decoration: none !important; }
      .account-wrap .box-container a .box {
        border: 2px solid #F7617E;
        border-radius: 6px;
        padding: 20px 40px;
        text-align: center;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;
        text-decoration: none !important; }
        .account-wrap .box-container a .box p {
          margin-top: 15px;
          margin-bottom: 0;
          color: #333333;
          font-size: 18px;
          line-height: 1;
          font-weight: 500;
          text-decoration: none !important; }

.find-client-box-container {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 40px;
  padding-bottom: 26px;
  margin-top: 10px; }
  @media only screen and (max-width: 768px) {
    .find-client-box-container {
      padding: 15px;
      padding-bottom: 26px; } }

.cart-wrap .cart-next-button-wrap {
  display: flex;
  align-items: center;
  justify-content: center; }

.cart-wrap .cart-next-button {
  background-color: #333333;
  color: #FFFFFF;
  padding: 10px 40px;
  border: 0;
  outline: none;
  border-radius: 3px;
  margin: 0 auto;
  transition: all 0.3s;
  margin-bottom: 20px; }
  .cart-wrap .cart-next-button:hover {
    background-color: #F7617E; }

.cart-wrap .box-container {
  margin-bottom: 0;
  padding-bottom: 10px; }

.cart-wrap .arrow-steps {
  width: 100%;
  display: flex;
  align-items: center; }
  .cart-wrap .arrow-steps > button {
    border: 0;
    outline: none;
    background-color: #FFF2FE;
    padding: 0 30px;
    width: 100%;
    margin: 0 10px;
    height: 64px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: background-color 0.3s; }
    .cart-wrap .arrow-steps > button span {
      z-index: 9; }
    @media only screen and (max-width: 767px) {
      .cart-wrap .arrow-steps > button {
        font-size: 12px;
        padding: 0;
        padding-left: 25px;
        margin: 0 5px;
        height: 48px; } }
    .cart-wrap .arrow-steps > button::after {
      content: ' ';
      display: block;
      width: 45px;
      height: 45px;
      transform: rotate(45deg);
      position: absolute;
      right: -23.5px;
      top: 9.5px;
      background-color: #FFF2FE;
      z-index: 2;
      transition: background-color 0.3s; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .arrow-steps > button::after {
          width: 34px;
          height: 34px;
          right: -17px;
          top: 5.5px; } }
    .cart-wrap .arrow-steps > button::before {
      content: ' ';
      display: block;
      width: 45px;
      height: 45px;
      transform: rotate(45deg);
      position: absolute;
      left: -23.5px;
      top: 9.5px;
      background-color: #fff;
      z-index: 1; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .arrow-steps > button::before {
          width: 34px;
          height: 34px;
          left: -17px;
          top: 5.5px; } }
    .cart-wrap .arrow-steps > button:first-child::before {
      display: none; }
    .cart-wrap .arrow-steps > button:hover, .cart-wrap .arrow-steps > button.active {
      background-color: #F7617E;
      color: #FFFFFF; }
      .cart-wrap .arrow-steps > button:hover::after, .cart-wrap .arrow-steps > button.active::after {
        background-color: #F7617E; }

.cart-wrap .table {
  border: 1px solid #D1D1D1; }
  @media only screen and (max-width: 991px) {
    .cart-wrap .table {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 16px; } }

.cart-wrap .cart-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0; }
  .cart-wrap .cart-progress > li {
    position: relative;
    text-align: center; }
    .cart-wrap .cart-progress > li::before {
      content: " ";
      position: absolute;
      display: block;
      height: 6px;
      width: 98px;
      background-color: #CCCCCC;
      border-radius: 13px;
      left: 100%;
      margin-left: -15px;
      top: 21px; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress > li::before {
          left: 150%;
          width: 30px; } }
      @media only screen and (min-width: 768px) and (max-width: 1112px) {
        .cart-wrap .cart-progress > li::before {
          width: 70px; } }
    .cart-wrap .cart-progress > li::after {
      content: " ";
      position: absolute;
      height: 6px;
      width: 0;
      background-color: #F7617E;
      border-radius: 13px;
      left: 100%;
      margin-left: -15px;
      top: 21px;
      -webkit-transition: width 0.5s;
      /* For Safari 3.1 to 6.0 */
      transition: width 0.5s; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress > li::after {
          left: 150%; } }
    .cart-wrap .cart-progress > li:last-child::before {
      display: none; }
    .cart-wrap .cart-progress > li:last-child::after {
      display: none; }
    .cart-wrap .cart-progress > li:first-child {
      margin-right: 70px; }
    .cart-wrap .cart-progress > li .icon {
      margin: 0 auto;
      margin-bottom: 16px;
      border: 4px solid #CCCCCC;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .cart-wrap .cart-progress > li .icon svg {
        fill: #F7617E;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px; }
    .cart-wrap .cart-progress > li p {
      max-width: 250px;
      text-align: center;
      margin-bottom: 0;
      font-size: 18px; }
      .cart-wrap .cart-progress > li p:nth-child(2) {
        font-size: 18px;
        font-weight: 600; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress > li p:nth-child(3) {
          display: none; } }
    .cart-wrap .cart-progress > li:nth-child(1) svg {
      fill: #F7617E; }
  .cart-wrap .cart-progress .active::after {
    width: 49px; }
    @media only screen and (min-width: 768px) and (max-width: 1112px) {
      .cart-wrap .cart-progress .active::after {
        width: 35px; } }
    @media only screen and (max-width: 767px) {
      .cart-wrap .cart-progress .active::after {
        width: 15px; } }
  .cart-wrap .cart-progress .active .icon {
    background-color: #F7617E;
    border: 0; }
  .cart-wrap .cart-progress .active:nth-child(1) svg {
    stroke: #FFFFFF; }
  .cart-wrap .cart-progress .active:nth-child(4) svg {
    fill: #FFFFFF;
    stroke: unset; }
  .cart-wrap .cart-progress .done::after {
    width: 98px;
    display: block; }
    @media only screen and (min-width: 768px) and (max-width: 1112px) {
      .cart-wrap .cart-progress .done::after {
        width: 70px; } }
    @media only screen and (max-width: 767px) {
      .cart-wrap .cart-progress .done::after {
        width: 30px; } }
  .cart-wrap .cart-progress .done .icon {
    background-color: #F7617E;
    border: 0; }
  .cart-wrap .cart-progress .done:nth-child(1) svg {
    stroke: #FFFFFF; }
  .cart-wrap .cart-progress .done:nth-child(4) svg {
    fill: #FFFFFF;
    stroke: unset; }

@media only screen and (max-width: 991px) {
  .cart-wrap .button-group {
    padding: 0 !important; } }

.cart-wrap .back {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 16px;
  height: 40px; }
  .cart-wrap .back::before {
    content: " ";
    display: block;
    border-bottom: 2px solid #F7617E;
    border-right: 2px solid #F7617E;
    transform: rotate(135deg);
    width: 8px;
    height: 8px;
    margin-right: 6px; }

.cart-wrap .checkout-button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }
  .cart-wrap .checkout-button .button {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cart-wrap .checkout-button .button::after {
      content: " ";
      display: block;
      border-bottom: 2px solid #FFFFFF;
      border-right: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      float: right;
      margin-left: 8px; }

.cart-wrap .checkout-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px; }
  .cart-wrap .checkout-buttons span {
    padding: 0 16px; }
  .cart-wrap .checkout-buttons .button {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cart-wrap .checkout-buttons .button::after {
      content: " ";
      display: block;
      border-bottom: 2px solid #FFFFFF;
      border-right: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      float: right;
      margin-left: 8px; }

.cart-wrap .notes-input .input-wrap {
  margin-bottom: 0; }
  .cart-wrap .notes-input .input-wrap input[type=text] {
    height: 36px; }

.cart-wrap .coupon-input .input-wrap {
  margin-bottom: 0;
  margin-bottom: 32px; }
  .cart-wrap .coupon-input .input-wrap input[type=text] {
    height: 36px;
    width: 120px; }

.cart-wrap .cart-table {
  border-bottom: 0;
  margin-bottom: 24px; }
  .cart-wrap .cart-table .header {
    height: 34px;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 991px) {
      .cart-wrap .cart-table .header {
        font-size: 10px;
        height: 26px;
        padding-top: 3px; } }
    .cart-wrap .cart-table .header > div {
      color: #333333;
      font-weight: 600;
      padding: 0; }
    .cart-wrap .cart-table .header .number {
      text-align: right;
      padding: 0 15px; }
  .cart-wrap .cart-table .article-info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D1D1D1;
    font-size: 14px; }
    .cart-wrap .cart-table .article-info > div {
      padding: 12px 8px;
      display: flex;
      align-items: center; }
      .cart-wrap .cart-table .article-info > div:last-child {
        border-right: 0; }
    .cart-wrap .cart-table .article-info .article-name {
      font-weight: 600; }
    .cart-wrap .cart-table .article-info .select-field {
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info .select-field {
          font-size: 10px; } }
    @media only screen and (max-width: 991px) {
      .cart-wrap .cart-table .article-info {
        font-size: 12px; } }
    .cart-wrap .cart-table .article-info input[type=text] {
      height: 36px;
      width: 50px;
      border-radius: 6px;
      border: 1px solid #D1D1D1;
      padding: 0 16px;
      margin-right: 8px; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info input[type=text] {
          width: 30px;
          padding: 0 8px; } }
    .cart-wrap .cart-table .article-info .number {
      justify-content: flex-end;
      padding-right: 12px; }
    .cart-wrap .cart-table .article-info .delete {
      justify-content: center; }
    .cart-wrap .cart-table .article-info label {
      margin-bottom: 0; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info label {
          display: none; } }
    .cart-wrap .cart-table .article-info img {
      width: 100px;
      margin-right: 12px; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info img {
          width: 60px; } }
  .cart-wrap .cart-table .payment-info {
    display: flex;
    align-items: center;
    font-size: 14px; }
    .cart-wrap .cart-table .payment-info > div {
      padding: 12px 8px;
      border-right: 1px solid #D1D1D1;
      border-bottom: 1px solid #D1D1D1;
      height: 500px; }
      .cart-wrap .cart-table .payment-info > div:last-child {
        border-right: 0; }
    .cart-wrap .cart-table .payment-info .select-field {
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .payment-info .select-field {
          font-size: 10px; } }
    @media only screen and (max-width: 991px) {
      .cart-wrap .cart-table .payment-info {
        font-size: 12px; } }
    .cart-wrap .cart-table .payment-info .number {
      display: flex;
      justify-content: flex-end;
      padding-right: 12px; }
    .cart-wrap .cart-table .payment-info .delete {
      display: flex;
      justify-content: center; }
    .cart-wrap .cart-table .payment-info label {
      margin-bottom: 0; }

.cart-wrap .small-table img {
  width: 42px !important;
  height: 42px;
  object-fit: contain; }

.skinconsult-wrap {
  padding-top: 40px; }
  .skinconsult-wrap .progress-indicator {
    width: 150px;
    margin: 0 auto;
    margin-bottom: 40px; }
    .skinconsult-wrap .progress-indicator p {
      display: none;
      font-size: 12px;
      font-weight: 500;
      color: #7B7B7B;
      margin-bottom: 3px;
      text-align: center;
      max-width: 50%; }
    .skinconsult-wrap .progress-indicator > div {
      background-color: #C2C2C2;
      height: 2px;
      width: 100%;
      position: relative; }
      .skinconsult-wrap .progress-indicator > div::after {
        content: ' ';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FFFFFF;
        border: 2px solid #C2C2C2;
        position: absolute;
        top: -3.5px;
        right: -4px; }
      .skinconsult-wrap .progress-indicator > div::before {
        content: ' ';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FFFFFF;
        border: 2px solid #C2C2C2;
        position: absolute;
        top: -3.5px;
        left: -4px; }
      .skinconsult-wrap .progress-indicator > div .dot-1 {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FFFFFF;
        border: 2px solid #C2C2C2;
        position: absolute;
        top: -3.5px;
        left: 25%;
        margin-left: -4px; }
      .skinconsult-wrap .progress-indicator > div .dot-2 {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FFFFFF;
        border: 2px solid #C2C2C2;
        position: absolute;
        top: -3.5px;
        left: 75%;
        margin-left: -4px; }
  .skinconsult-wrap .step-1 .camera {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3; }
    .skinconsult-wrap .step-1 .camera video, .skinconsult-wrap .step-1 .camera img, .skinconsult-wrap .step-1 .camera canvas {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: #000; }
    .skinconsult-wrap .step-1 .camera video {
      transform: scale(-1, 1); }
    .skinconsult-wrap .step-1 .camera canvas {
      position: absolute;
      top: 0;
      left: 0;
      background-color: transparent !important; }
    .skinconsult-wrap .step-1 .camera .loader-svg {
      position: absolute;
      margin-top: -155px;
      margin-left: -155px;
      top: 50%;
      left: 50%; }
    .skinconsult-wrap .step-1 .camera > button {
      position: absolute;
      bottom: 80px;
      left: 50%;
      margin-left: -40px;
      border: 0;
      outline: none;
      background-color: #FFFFFF;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
      -moz-box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
      box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
      width: 81px;
      height: 81px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 14px;
      margin-bottom: 13px; }
    .skinconsult-wrap .step-1 .camera .error-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 500px;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 10px;
      color: #FFFFFF;
      margin-left: -250px;
      margin-top: -50px;
      text-align: center;
      padding: 20px; }
      @media only screen and (max-width: 767px) {
        .skinconsult-wrap .step-1 .camera .error-modal {
          width: 90%;
          left: 5%;
          margin-left: 0; } }
      .skinconsult-wrap .step-1 .camera .error-modal button {
        font-size: 22px;
        font-weight: 500;
        color: #FFFFFF;
        background-color: #F76985;
        padding: 5px 24px;
        border-radius: 18px;
        border: 0;
        outline: none;
        position: relative;
        width: 100%; }
  .skinconsult-wrap .step-1 .progress-indicator .progress-1 {
    display: block; }
  .skinconsult-wrap .step-1 .progress-indicator > div .dot-1 {
    left: 50%; }
  .skinconsult-wrap .step-1 .progress-indicator > div .dot-2 {
    left: 75%; }
  .skinconsult-wrap .step-1 .info {
    margin-bottom: 52px; }
    .skinconsult-wrap .step-1 .info > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px; }
      .skinconsult-wrap .step-1 .info > div > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 24px; }
        .skinconsult-wrap .step-1 .info > div > div button {
          border: 0;
          outline: none;
          background-color: #FFFFFF;
          -webkit-box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
          -moz-box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
          box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
          width: 81px;
          height: 81px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 14px;
          margin-bottom: 13px; }
        .skinconsult-wrap .step-1 .info > div > div p {
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #7B7B7B;
          margin-bottom: 0; }
    .skinconsult-wrap .step-1 .info > p {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: #7B7B7B;
      margin-bottom: 0; }
  .skinconsult-wrap .step-1 .steps-wrap {
    margin-bottom: 41px; }
    @media only screen and (max-width: 767px) {
      .skinconsult-wrap .step-1 .steps-wrap {
        overflow-x: scroll;
        margin: 0 -15px;
        padding-left: 15px;
        margin-bottom: 41px; } }
  .skinconsult-wrap .step-1 .steps {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .skinconsult-wrap .step-1 .steps {
        width: 150%;
        padding-top: 18px; } }
    .skinconsult-wrap .step-1 .steps > div {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: visible;
      width: 33.33%;
      padding: 0 18px; }
      .skinconsult-wrap .step-1 .steps > div .num {
        background-color: #F76985;
        border-radius: 9px;
        position: absolute;
        top: -16px;
        left: 0px;
        width: 36px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 700; }
      .skinconsult-wrap .step-1 .steps > div > img {
        width: 100%;
        height: 320px;
        object-fit: cover;
        margin-bottom: 16px; }
        @media only screen and (max-width: 767px) {
          .skinconsult-wrap .step-1 .steps > div > img {
            height: 160px; } }
      .skinconsult-wrap .step-1 .steps > div > p {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: #7B7B7B;
        margin-bottom: 0; }
  .skinconsult-wrap .step-2 {
    margin-bottom: 50px; }
    .skinconsult-wrap .step-2 .progress-indicator .progress-2 {
      display: block;
      margin-left: auto;
      margin-right: auto; }
    .skinconsult-wrap .step-2 .progress-indicator > div .dot-1 {
      left: 25%; }
    .skinconsult-wrap .step-2 .progress-indicator > div .dot-2 {
      left: 75%; }
    .skinconsult-wrap .step-2.step-3 .progress-indicator .progress-2 {
      display: none; }
    .skinconsult-wrap .step-2.step-3 .progress-indicator .progress-3 {
      display: block;
      margin-left: auto; }
    .skinconsult-wrap .step-2.step-3 .progress-indicator > div .dot-1 {
      left: 25%; }
    .skinconsult-wrap .step-2.step-3 .progress-indicator > div .dot-2 {
      left: 50%; }
    .skinconsult-wrap .step-2 .diagnose-wrap {
      width: 100%;
      position: relative;
      padding: 44px 36px; }
      @media only screen and (max-width: 767px) {
        .skinconsult-wrap .step-2 .diagnose-wrap {
          padding: 44px 15px; } }
      .skinconsult-wrap .step-2 .diagnose-wrap::before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 152px;
        display: block;
        background-color: #F76985; }
      .skinconsult-wrap .step-2 .diagnose-wrap .diagnose {
        margin-bottom: 30px;
        background-color: #FFFFFF;
        padding: 24px 32px;
        width: 100%;
        -webkit-box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
        -moz-box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
        box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36); }
        .skinconsult-wrap .step-2 .diagnose-wrap .diagnose h3 {
          font-size: 23px;
          font-weight: 700;
          color: #000;
          margin-bottom: 19px;
          text-align: center; }
        .skinconsult-wrap .step-2 .diagnose-wrap .diagnose .items {
          width: 100%;
          display: flex;
          flex-wrap: wrap; }
          .skinconsult-wrap .step-2 .diagnose-wrap .diagnose .items h6 {
            color: #F76985;
            font-weight: 700; }
      .skinconsult-wrap .step-2 .diagnose-wrap .products h3 {
        font-size: 23px;
        font-weight: 700;
        color: #000;
        margin-bottom: 19px;
        text-align: center; }
      .skinconsult-wrap .step-2 .diagnose-wrap .products > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }
        .skinconsult-wrap .step-2 .diagnose-wrap .products > div > div {
          width: 25%;
          flex: 0 0 25%;
          padding: 15px;
          display: flex;
          flex-direction: column; }
          @media only screen and (max-width: 767px) {
            .skinconsult-wrap .step-2 .diagnose-wrap .products > div > div {
              width: 50%;
              flex: 0 0 50%; } }
          .skinconsult-wrap .step-2 .diagnose-wrap .products > div > div a {
            color: #333333;
            text-decoration: none; }
          .skinconsult-wrap .step-2 .diagnose-wrap .products > div > div h6 {
            font-size: 19px;
            font-weight: 400;
            margin-bottom: 10px;
            text-align: center;
            color: #333333;
            min-height: 44px; }
            @media only screen and (max-width: 767px) {
              .skinconsult-wrap .step-2 .diagnose-wrap .products > div > div h6 {
                min-height: 88px;
                display: flex;
                align-items: center; } }
          .skinconsult-wrap .step-2 .diagnose-wrap .products > div > div .more {
            text-decoration: underline;
            font-size: 16px;
            text-align: center;
            margin: 5px 0; }
          .skinconsult-wrap .step-2 .diagnose-wrap .products > div > div img {
            width: 100%; }
          .skinconsult-wrap .step-2 .diagnose-wrap .products > div > div button {
            font-size: 12px;
            font-weight: 500;
            color: #FFFFFF;
            background-color: #F76985;
            border-radius: 18px;
            padding: 5px 10px;
            border: 0;
            outline: none;
            position: relative;
            width: 100%; }
    .skinconsult-wrap .step-2 .questionary {
      text-align: center;
      max-width: 500px;
      margin: 0 auto; }
      .skinconsult-wrap .step-2 .questionary h6 {
        font-size: 22px;
        font-weight: 500;
        color: #7B7B7B;
        text-align: center;
        margin-bottom: 50px; }
      .skinconsult-wrap .step-2 .questionary .question .description {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px; }
        .skinconsult-wrap .step-2 .questionary .question .description > div {
          margin-left: 22px;
          max-width: 150px; }
          .skinconsult-wrap .step-2 .questionary .question .description > div h3 {
            font-size: 19px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 17px; }
          .skinconsult-wrap .step-2 .questionary .question .description > div p {
            text-align: left;
            margin-bottom: 0;
            font-size: 14px; }
      .skinconsult-wrap .step-2 .questionary .question .circle-description {
        display: flex;
        margin-top: 20px;
        position: relative; }
        .skinconsult-wrap .step-2 .questionary .question .circle-description img {
          width: 138px;
          height: auto;
          height: 160px;
          object-fit: cover; }
        .skinconsult-wrap .step-2 .questionary .question .circle-description::before {
          content: ' ';
          display: block;
          position: absolute;
          top: 10px;
          left: -10px;
          width: 128px;
          height: 160px;
          background-color: #FFDEF2;
          z-index: -1; }
        .skinconsult-wrap .step-2 .questionary .question .circle-description > div {
          margin-left: 22px; }
          .skinconsult-wrap .step-2 .questionary .question .circle-description > div h3 {
            font-size: 19px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 17px; }
          .skinconsult-wrap .step-2 .questionary .question .circle-description > div p {
            text-align: left;
            margin-bottom: 0;
            font-size: 14px; }
          .skinconsult-wrap .step-2 .questionary .question .circle-description > div > div {
            text-align: left; }
            .skinconsult-wrap .step-2 .questionary .question .circle-description > div > div .show-more {
              border: 0;
              outline: none;
              text-align: left;
              background-color: transparent;
              border-bottom: 1px solid #707070;
              width: auto;
              padding: 0;
              display: flex;
              align-items: center;
              margin-top: 10px; }
              .skinconsult-wrap .step-2 .questionary .question .circle-description > div > div .show-more::after {
                content: ' ';
                display: block;
                width: 8px;
                height: 8px;
                border-bottom: 1px solid #707070;
                border-right: 1px solid #707070;
                transform: rotate(-45deg);
                margin-left: 8px; }
      .skinconsult-wrap .step-2 .questionary .question input {
        border: 0;
        outline: none;
        border-bottom: 2px solid #F76985;
        font-size: 58px;
        text-align: center;
        font-weight: 500;
        width: auto;
        width: 100%;
        color: #7B7B7B; }
      .skinconsult-wrap .step-2 .questionary .question .options {
        text-align: center; }
        .skinconsult-wrap .step-2 .questionary .question .options .answer {
          padding: 12px 20px;
          background-color: #FFFFFF;
          font-size: 16px;
          font-weight: 500;
          color: #7B7B7B;
          text-align: center;
          border: 0;
          outline: none;
          border-radius: 10px;
          -webkit-box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
          -moz-box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
          box-shadow: 0px 3px 6px 0px rgba(58, 56, 56, 0.36);
          margin-bottom: 26px;
          margin-left: 10px;
          margin-right: 10px; }
          .skinconsult-wrap .step-2 .questionary .question .options .answer.active {
            background-color: #F76985;
            color: #FFFFFF; }
      .skinconsult-wrap .step-2 .questionary .question.question-with-image .options .answer {
        width: 100%;
        margin-left: 0;
        margin-right: 0; }
      .skinconsult-wrap .step-2 .questionary .question .circle-options-wrap {
        position: relative;
        width: 340px;
        height: 340px;
        margin: 0 auto; }
        .skinconsult-wrap .step-2 .questionary .question .circle-options-wrap .answer {
          position: absolute;
          width: 76px;
          height: 40px;
          padding: 0;
          border: 0;
          outline: none;
          text-align: center;
          font-size: 14px;
          line-height: 17px;
          background-color: transparent;
          color: #000000;
          font-weight: 700;
          overflow: visible; }
          @media only screen and (max-width: 767px) {
            .skinconsult-wrap .step-2 .questionary .question .circle-options-wrap .answer {
              font-size: 12px; } }
          .skinconsult-wrap .step-2 .questionary .question .circle-options-wrap .answer.active {
            color: #F76985; }
        .skinconsult-wrap .step-2 .questionary .question .circle-options-wrap .dot {
          position: absolute;
          width: 14px;
          height: 14px;
          padding: 0;
          border: 0;
          outline: none;
          background-color: #757575;
          border-radius: 50%;
          z-index: 1; }
          .skinconsult-wrap .step-2 .questionary .question .circle-options-wrap .dot.active {
            background-color: #F76985; }
        .skinconsult-wrap .step-2 .questionary .question .circle-options-wrap::after {
          content: ' ';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 220px;
          height: 220px;
          margin-left: -110px;
          margin-top: -110px;
          border: 1px solid #707070;
          border-radius: 50%; }
        .skinconsult-wrap .step-2 .questionary .question .circle-options-wrap .rlogo {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 174px;
          height: 174px;
          margin-left: -87px;
          margin-top: -87px; }
      .skinconsult-wrap .step-2 .questionary .next-button {
        margin-top: 60px;
        font-size: 27px;
        font-weight: 500;
        color: #FFFFFF;
        background-color: #F76985;
        padding: 5px 24px;
        border-radius: 18px;
        border: 0;
        outline: none;
        position: relative;
        width: 100%; }
  .skinconsult-wrap .intro .content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .skinconsult-wrap .intro .content h3 {
      font-weight: 400;
      margin-bottom: 4px;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .skinconsult-wrap .intro .content h3 {
          font-size: 23px; } }
      .skinconsult-wrap .intro .content h3 > span {
        font-weight: 600; }
    .skinconsult-wrap .intro .content p {
      font-size: 15px;
      text-align: center; }
    .skinconsult-wrap .intro .content img {
      max-width: 100%;
      margin-bottom: 50px; }
    .skinconsult-wrap .intro .content button {
      font-size: 27px;
      font-weight: 500;
      color: #FFFFFF;
      background-color: #F76985;
      padding: 5px 24px;
      border-radius: 18px;
      border: 0;
      outline: none;
      position: relative; }
      .skinconsult-wrap .intro .content button::before {
        content: ' ';
        display: block;
        position: absolute;
        background-image: url(./assets/skinconsult/line.svg);
        width: 8px;
        height: 218px;
        z-index: -1;
        top: -29px;
        left: 50%;
        margin-left: -4px; }
  .skinconsult-wrap .intro .badges {
    background-color: #FFE5FC;
    padding: 15px 0;
    margin-top: 54px; }
    .skinconsult-wrap .intro .badges h3 {
      font-weight: 700;
      margin-bottom: 4px;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .skinconsult-wrap .intro .badges h3 {
          font-size: 18px; } }
    .skinconsult-wrap .intro .badges p {
      font-size: 15px;
      text-align: center; }
    .skinconsult-wrap .intro .badges .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px; }
      .skinconsult-wrap .intro .badges .item svg {
        margin-bottom: 10px; }
